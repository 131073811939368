.writing-review{
    margin-top: 30px;
    .view-answerExams{
        margin-top: 30px;
        .your-answer,.recommend-answer{
            text-decoration: underline;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            text-underline-offset: 5px;
        }
        .recommend-answer{
            margin-top: 30px;
        }
        .answer-detail,.recommend-detail {
            
            p{
                padding: 30px;
                border: 1px solid #FFFFFF80;
                border-radius: 5px;
                max-height: 35vh;
                overflow-y: scroll;
                font-size: 14px;
            }
            p::-webkit-scrollbar {
                width: 8px;
            }
            p::-webkit-scrollbar-thumb {
                background: #FFFFFF; 
                border-radius: 10px;
              }
              /* Track */
            p::-webkit-scrollbar-track {
                background: #D9D9D94D; 
            }

            .editor-container{
                .ck{
                    background: none;
                    padding: 0;
                    margin: 0;
                    p{
                        margin: 0; 
                        .ck-comment-marker{
                            color: #000;
                        }
                    }
                }
                .ck-content{
                    border: none;
                }
            }


        }
        .recommend-detail{
            background: rgba(0, 240, 255, 0.05);
        }
        // .{
        //     border: 0.5px solid #FFFFFF80
        // }
    }
    .correct-true{
        color: #00F043;
        font-weight: bold;
    }
    // .correct-false{
    //     color: #FB545C;
    //     font-weight: bold;
    // }
}