.listening{
    .notepad{
        width: 100%;
        background: rgba(255, 255, 255, 0.25);
        border-radius: 8px;
        color: #FFFFFF;
        padding: 10px;
        @media (min-resolution: 120dpi) {
           color: #000;
           background: #FFFFFF;
        }
    }:focus-visible{
        border: none;
        box-shadow: none;
        outline: none;
        @media (min-resolution: 120dpi) {
            outline: #000;
        }
    }
}