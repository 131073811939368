.box-recorder {
  height: 30vh;
  background: rgba(0, 0, 0, 0.18);
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  border-radius: 5px;

  @media (min-resolution: 120dpi) {
    margin: 13px;
    background: none;
    border-color: #000;
  }

  .recorder-header {
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
  }

  .recorder-content {
    height: 60%;
    // background-color: aqua;
  }

  .recorder-footer {
    height: 30%;

    .row {
      height: 100%;
    }

    ul {
      margin: 0;
      display: flex;
      height: 100%;
      align-items: center;

      li {
        list-style-type: none;
        float: left;
        flex: 1;

        .btn-record {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          border: 2px solid #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin: auto;
        }

        .btn-play {
          background-color: red;
        }

        .btn-stop {
          color: red;
        }

        .btn-pause {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin: auto;
        }
      }
    }
  }
}
