.box-overview,.box-analysis{
    backdrop-filter: blur(8.8px);
    background: hsla(0,0%,100%,.13);
    border: 1px solid hsla(0,0%,100%,.18);
    padding: 20px 10px;
    margin-top: 20px;
    .overview-title,.analysis-title{
        text-align: center;
        font-size: 24px;
    }
    .overview-note{
        color: #c3c3c3;
    }
    .box-score{
        display: flex;
        .score-circle{
            flex: 1;
        }
        .score-skill{
            flex: 5;
            padding:0 20px;                
            .skill-name{
                border-bottom: 1px solid #FFFFFF;
                p{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                }
            }
            .skill-score-detail{
                padding: 10px 0;
                p{
                    color: #c3c3c3;
                    span{
                        color: #FFFFFF;
                        // font-size: 18px;
                        font-weight: 600;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}