.input-test-list{
    .input-test-title{
        font-size: 26px;
        font-weight: bold;
        line-height: 27.3px;
        text-align: center;
        color: #131318;
        text-transform: uppercase;
    }
    .list-test{
        margin-top: 20px;
        .card{
            margin: 20px 0;
            .card-body{
                padding: 0;
            }
            padding: 15px;
            border-radius: 30px;
            border: none;
            .card-top{
                display: flex;
                .top-left{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    img{
                        width: 100%;
                        padding: 10px;
                        border-radius: 50%;
                        border: 2px solid #3060CF;
                    }
                }
                .top-right{
                    flex: 5;
                    padding-left: 20px;
                    .top-right-title{
                        color: #161F38;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: start;
                        margin: 0;
                    }
                    .top-right-guide{
                        color: #B0B1B2;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        text-align: left;
                        margin: 10px 0;
                    }
                }
            }
            .card-bottom{
                display: grid;
                column-gap: 10px;
                grid-template-columns: auto auto;
                
            }
            .card-bottom,.card-bottom1{
                margin-top: 15px;
            }
            .btn-test{
                border: 1px solid #3060CF;
                border-radius: 20px;
                color: #3060CF;
                padding: 10px;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                line-height: 22px;
            }
            .btn-full-test{
                background-color: #3060CF;
                color: #fff;
                width: 100%;
            }
        }
    }                
}