.my-account{
  .box-information{
    .form-group{
        input{
            background: rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            height: 48px;
            border: none;
            color: #FFFFFF;
            font-size: 16px;
        }:active{
            border: none;
        }
        .edit-btn{
            cursor: pointer;
            margin-left: 10px;
        }
        .label{
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 5px;
        }
        button{
            color: #FFFFFF;
            border-color: #FFFFFF;
            width: 110px;
            margin-right: 10px;
            font-weight: bold;
        }
        .cursor-not-allowed{
            cursor: not-allowed;
            user-select: none; 
        }
    }
  }
}
