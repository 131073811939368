.box-input-code{
    position: absolute;
    height: 80vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .form-input-code{
        .form-group{
            margin-bottom: 15px;
            
        }    
        .btn{
            width: 100%;
        }           
    }
}
