.speaking{
    min-height: calc(100vh - 80px);    
    .speaking-content{
        min-height: calc(100vh - 190px);
        padding: 30px 0;
    }
    .speaking-part{
        // height: calc(100% - 30px);
        // background: rgba(0, 0, 0, 0.18);
        // border: 0.5px solid rgba(255, 255, 255, 0.5);
        
        backdrop-filter: blur(8.6px);
        border-radius: 5px;
        @media (min-resolution: 120dpi) {
          background: unset;
        }
        .active{
            // background-color: #00F0FF;
            // border: 2px solid #00F0FF;
            color: #000000 !important;
            @media (min-resolution: 120dpi) {
                background-color: #fff;
                border: 2px solid #fff !important;
            }
            // .content{
                // color: #000000;
            // }
        }
        .box-speaking-part{
            padding: 15px;
            margin: 15px;
            // border: 0.5px solid rgba(255, 255, 255, 0.5);        
            border: 1px solid #000;  
            border-radius: 5px;
            // color: #FFFFFF;
            
            .box-content{
                max-height: 25vh;
                overflow: auto;
            }
            @media (min-resolution: 120dpi) {
                color: #000000;
                border: 1px solid;
                max-height: 40vh;
                overflow: scroll;
            }
            .content{
                font-weight: 400;
                font-size: 16px;
                // color: #ccc;
                // color: #FFFFFF;
                line-height: 24px;
                @media (min-resolution: 120dpi) {
                    color: #000000;
                }
                p{
                    margin:5px;
                }
                figure{
                    color: #FFFFFF;
                    @media (min-resolution: 120dpi) {
                        color: #000000;
                    }
                }
            }
        }
        .box-content::-webkit-scrollbar {
            width: 6px;
        }
        .box-content::-webkit-scrollbar-thumb {
            background: #3060CF; 
            border-radius: 10px;
          }
          /* Track */
        .box-content::-webkit-scrollbar-track {
            background: #ccc; 
        }
        
    }
    .successfull{
        height: 40px;
        background: rgba(255, 255, 255, 0.1);
        border: 0.5px solid rgba(255, 255, 255, 0.5);
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        p{
            margin: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            padding-left: 15px;
            color: #ccc;
            img{
                margin-right: 10px;
            }
        }
    }
}
