.section-test {
  background-color: #080e28;
  padding: 150px 0;
  .test--tile {
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    @media (max-width: 576px) {
      font-size: 22px;
    }
  }
  .test--list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.text-\[clamp\(24px\,5vw\,40px\)\] {
  font-size: clamp(24px, 5vw, 40px);
}

.font-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.mb-10 {
  margin-bottom: 2.5rem;
}
.mt-10 {
  margin-top: 40px;
}

.drop-shadow-\[0_0_20px_rgba\(0\,240\,255\,0\.7\)\] {
  filter: drop-shadow(0 0 20px rgba(0, 240, 255, 0.7));
}
