#header{
  
  /* Color of the links BEFORE scroll */
  // .navbar-scroll .nav-link,
  // .navbar-scroll .navbar-toggler .fa-bars {
  //   color: #fff;
  //   text-align: center;
  // }
  // .navbar-scroll .nav-link:hover{
  //   font-size: 20px;
  //   transition: all 0.5s ease;
  // }
  // /* Color of the links AFTER scroll */
  // .navbar-scrolled .nav-link,
  // .navbar-scrolled .navbar-toggler .fa-bars {
  //   color: #4f4f4f;
  // }

  // /* Color of the navbar AFTER scroll */
  // .navbar-scrolled {
  //   background-color: #fff;
  // }

  // /* An optional height of the navbar AFTER scroll */
  // .navbar.navbar-scroll.navbar-scrolled {
  //   padding-top: 5px;
  //   padding-bottom: 5px;
  // }
  // .main-logo{
  //   flex: 2;
  // }
  // .navbarsItem{
  //   flex: 6;
  //   .navbarsItemList{
  //     width: 100%;
  //     ul{
  //       width: 100%;
  //       li {
  //         width: 20%;
  //         font-style: normal;
  //         font-weight: 600;
  //         font-size: 18px;
  //         line-height: 22px;
  //       }
  //     }
  //   } 
  // }
  // .language{
  //   flex: 2;
  //   .box-language{
  //     color: #FFFFFF;
  //     span{
  //       border-radius: 8px;
  //       border: 1px solid #FFFFFF;
  //       padding: 10px 20px 12px 20px;
  //       img{
  //         border-radius: 50%;
  //       }
  //     }
  //     .dropdown-menu li{
  //       padding: 0 10px;
  //     }
  //   }
  // }

  #intro {
    // background-image: url("../../../../public/images/bg.gif");
    // background-size: cover;
    height: 100vh;
    background-color: #080E28;
    #tsparticles {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .mask{
      height: 100vh;
      width: 100%;
      // position: absolute;
      // background-color: rgba(6, 11, 36, 0.7);
      .mask-content{
        height: 60vh;
        // flex-direction: column;
        position: relative;
        z-index: 999;
        .giveatry{
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 900;
          font-size: 80px;
          line-height: 98px;
          // text-align: center;
          color: #FFFFFF;
          text-shadow: 0px 0px 20px rgba(0, 240, 255, 0.3);
          
        }
        .btn-testnow 
        {
          margin-top: 50px;   
          animation: pulse 1s infinite;
          transition: .3s;       
          a{
            // width: 100%;
            width: 100px;
            background: #FFF;
            border-radius: 4px;
            padding: 10px 30px;
            text-decoration: none;
            color: #080E28;
            font-weight: bold;            
          }                  
        }       
        @keyframes pulse {
          0% {
          transform: scale(1);
          }
          70% {
          transform: scale(.9);
          }
          100% {
          transform: scale(1);
          }
      }
         
        .with-ai{
          // font-family: 'Inter';
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          line-height: 36px;
        }
        
        @media (max-width: 576px) {
          .giveatry{
            font-size: 30px;
            line-height: 37px;        
          }
          .with-ai{
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
      .muiten{
        width: 150px;
        height: 200px;
        margin: auto;
        img{
            width: 100%;
        }
      }
    }
    .bg-hidden{
      height: 10vh;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 240, 255, 0.36) -83.8%, rgba(0, 0, 0, 0) 100%);
    }
  }  
}
