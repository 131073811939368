/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  color: #ffffff;
  font-family: "Montserrat";
  /* font-style: normal; */
}
.color-ant {
  color: #00f0ff !important;
}
.color-black {
  color: #000000 !important;
}
.bg-color-ant {
  background-color: #00f0ff !important;
}
.color-ccc {
  color: #cccccc !important;
}
