.home-light{
    padding: 0;
    margin: 0;
    height: auto;
    background: rgba(245, 248, 255, 1);
    // background-color: #000;
    .header{
        height: 100vh;
        width: 100vw;
        // background: url("../../../public/images/light/header-bg.png"),url("../../../public/images/light/bottom-bg.png");
        background: url("../../../public/images/light/bottom-bg.png"),url("../../../public/images/light/header-bg.png");
        background-position: left bottom,left top;
        background-repeat: no-repeat, no-repeat;
        background-size: contain ,cover;
        .head-content{
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;        
            .main-content{
                font-family: Montserrat;
                font-size: 80px;
                font-weight: 700;
                line-height: 97.52px;
            }
            .sub-content{
                margin-top: 10px;
                font-family: Montserrat;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.57px;

            }
        }
    }
    .content-light{
        padding: 100px;
        
        // background: url("../../../public/images/light/header-bg1.png");
        // background-repeat: no-repeat;
        // background-size: cover;
        
    }
    .about-us{
        .title{
            color: #000;
            font-size: 40px;
            font-weight: 700;
            line-height: 42px;

        }
        .list-card{
            // padding: 0 15px;
            margin-top: 100px;
            .row-card{
                display: flex;
                column-gap: 30px;
                // grid-template-columns: auto auto auto;
                margin: 40px 0;
                justify-content: center;
                .box-card{
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    a{
                        text-decoration: none;
                        width: 80%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .card{
                        background-color: #fff;
                        border-radius: 15px;
                        border: none;
                        padding: 20px;
                        // width: 80%;
                        .card-body{
                            padding: 0;
                            .card-text{
                                color: #131318;
                                text-align: center;
                            }
                        }
                    }
                    .card-height{
                        padding-bottom: 100px;
                    }
                }
            }
            .row-card-2{
                width: 66%;
                display: flex;
            }
            
        }
        
    }
    .input-test-light{
        padding-top: 100px;
        .input-test-title{
            font-size: 26px;
            font-weight: bold;
            line-height: 27.3px;
            text-align: center;
            color: #131318;
            text-transform: uppercase;
        }
        .list-test{
            margin-top: 50px;
            display: flex;
            column-gap: 30px;
            .card{
                flex: 1;
                margin: 20px 0;
                .card-body{
                    padding: 20px;
                }
                padding: 15px;
                border-radius: 30px;
                border: none;
                .card-top{
                    display: flex;
                    .top-left{
                        flex: 1;
                        display: flex;
                        align-items: center;
                        img{
                            width: 100%;
                            padding: 10px;
                            border-radius: 50%;
                            border: 2px solid #3060CF;
                        }
                    }
                    .top-right{
                        flex: 5;
                        padding-left: 20px;
                        .top-right-title{
                            color: #161F38;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 24px;
                            text-align: start;
                            margin: 0;
                        }
                        .top-right-guide{
                            color: #B0B1B2;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            text-align: left;
                            margin: 10px 0;
                        }
                    }
                }
                .card-bottom{
                    display: grid;
                    column-gap: 10px;
                    grid-template-columns: auto auto;
                    
                }
                .card-bottom,.card-bottom1{
                    margin-top: 15px;
                }
                .btn-test{
                    border: 1px solid #3060CF;
                    border-radius: 20px;
                    color: #3060CF;
                    padding: 10px;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 22px;
                }:hover{
                    cursor: pointer;
                }
                .btn-full-test{
                    background-color: #3060CF;
                    color: #fff;
                    width: 100%;
                }
            }
        }                
    }
    .contact-light{
        min-height: 100vh;
        padding-top: 100px;
        display: flex;
        .contact-map{
            flex: 4;
            padding-right: 20px;
            iframe{
                width: 100%;
                height: 100%;
            }
        }
        .box-contact{  
            flex: 5;
            padding: 50px;
            background: #fff;
            border-radius: 30px;              
            .contact-title{
                text-align: left;
                color: #131318;
                text-transform: uppercase;
                font-family: Montserrat;
                font-size: 40px;
                font-weight: 700;
                line-height: 63.98px;

            }
            .contact-title-sub{
                margin-top: 10px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #131318;
                margin-bottom: 30px;
            }
            .input-row{
                label{
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.0015em;
                    text-align: left;
                    color: #131318;
                }
                input{
                    margin-top: 5px;
                    padding: 15px;
                }
                input::placeholder {
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: 0.005em;
                    text-align: left;

                }
                .btn-submit{
                    background-color: #3060CF;
                    color: #fff;
                    width: 100%;
                    border: 1px solid #3060CF;
                    border-radius: 20px;
                    padding: 10px;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
        }
    }
    .footer-light{
        min-height: 20vh;
        padding: 50px 0;
        .container-fluid{
            .head{
                .box-progress{
                    display: flex;
                    align-items: center;
                   
                    .progress{
                        height: 1px;
                        width: 100%;
                        .progress-bar{
                            background-color: #000;
                        }
                    }
                }
                .title p{
                    color: #000000;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 37px;
                    margin: 0;
                }
            }
            .btn-info{
                width: 250px;
                height: 48px;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-decoration-line: underline;
                color: #fff;
                background: #3060CF;
                border: none;
                border-radius: 4px;
                i{
                    margin-right: 10px;
                }
            }
            .location{
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                color: #000000;
                i{
                    margin-right: 10px;
                }
            }
            // .row-phone{
            //     p{
            //         font-style: normal;
            //         font-weight: 600;
            //         font-size: 16px;
            //         line-height: 20px;
            //     }
            // }
            .row-list-icon{
                ul {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    li{
                        list-style-type: none;
                        float: left;
                        margin:0 20px;
                        border-radius: 50%;
                        border: 1px solid #000;
                        width: 48px;
                        height: 48px; 
                        @media (max-width: 576px) {
                            width: 38px;
                            height: 38px; 
                            margin:0 12px;
    
                        }                 
                        a{
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-decoration: none;
                            i{
                                color: #000;
                            }
                        }
                        
                    }:hover{
                        border-color:  #3060CF;
                        i{
                            color:  #3060CF;;
                        }
                    }
                }
            }           
        }
    }
}