.auth-logo{
    position: absolute;
    margin: 16px;
    img{
        width: 50%;
        max-width: 100px;
        min-width: 100px;
    }
}
.loginpage{
    // background: url("../../../../public/images/auth-bg.png");
    background-size: cover;
    background-position: bottom;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
   
    .container{
        display: flex;
   
        .col-left{
            flex: 1;
            @media (max-width: 576px) {
                display: none;
            }
            img{
                width: 638px;
            }
        }
        .col-right{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .box-form-group{
                width: 80%;
                background: rgba(48, 96, 207, 0.1)                ;
                // border: 1px solid #000000;
                border-radius: 17px;
                padding: 35px;
                @media (max-width: 576px) {
                    width: 100%;
                    padding: 20px;
                }
                .form-group-title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 39px;
                    @media (max-width: 576px) {
                        font-size: 28px;
                    }                    
                }
                .form-group-sub-title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21px;
                    color: #CCCCCC;
                }
                .form-group-success{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 21px;
                    color: #00F0FF
                }
                .form-group{
                    .input-group{
                        display: flex;
                        justify-content: center;
                        align-self: center;
                        height: 59px;
                        background: #FFFFFF;
                        border: 1px solid #D5D4DC;
                        border-radius: 30px;
                        // margin: 26px 0;
                        @media (max-width: 576px) {
                            height: 45px;
                            margin: 10px 0;
                        }
                        .input-group-prepend{
                            flex: 1;
                            display: flex;      
                            justify-content: center;
                            align-self: center;                  
                            i{
                                align-items: center;
                                color: #ccc;
                            }
                        }
                        input{
                            flex: 6;
                            border: 2px solid #D5D4DC;
                            border-bottom-right-radius: 30px;
                            border-top-right-radius: 30px;
                            border: none;
                        }:focus{
                            box-shadow:none;
                            border: 1px solid #D5D4DC;

                        }::placeholder {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            color: rgba(73, 73, 73, 0.6);
                            
                        }
                        .input-group-text{
                            background: none;
                            border: none;
                            i{
                                color: #CCCCCC;
                            }:hover{
                                cursor: pointer;
                            }
                        }
                        .form-control-show{
                            border-radius:unset ;
                        }
                    }                
                }
                .btn-auth{
                    margin-top: 30px;
                    width: 235px;
                    height: 59px;
                    // background: #00F0FF;
                    border-radius: 29.5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (max-width: 576px) {
                        width: 180px;
                        height: 45px;
                        margin: auto;
                    }
                    p{
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 24px;
                        text-align: center;
                        margin: 0;
                        // color: #000000;
                    }
                }
                .color-anttest{
                    // color: #00F0FF;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                }
                .form-check-label{
                    @media (max-width: 576px) {
                        font-size: 14px;
                    }                    
                }
            }
            .box-form-social{
                width: 80%;
                margin-top: 30px;
                display: flex;
                @media (max-width: 576px) {
                    width: 100%;
                }    
                .social{
                    flex: 1;
                    height: 47.43px;
                    .social-fb{
                        height: 100%;
                        width: calc(100% - 10px);
                        background: #407CD7;
                        border-radius: 9px;
                        margin-right: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media (max-width: 576px) {
                            font-size: 11px;
                            // margin-right: 5px;
                        } 
                    }
                    .social-gg{
                        width: calc(100% - 10px);
                        height: 100%;
                        background: #D81C1C;
                        border-radius: 9px;
                        margin-left: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media (max-width: 576px) {
                            font-size: 11px;
                        } 
                    }
                }:hover{
                    cursor: pointer;
                }
                
            }
        }
    }
}