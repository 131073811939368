@media (max-width: 576px) {
    .box-recorder{
        height: 10vh;
        display:block;
        position: relative;
        #mycanvas{
            position: absolute;
        }
        .recorder-footer{
            height: 100%;
            width: 100%;
            position: absolute;
            ul{
                margin: 0;
                display: flex;
                height: 100%;
                align-items: center;
                padding: 0;
                .count-time{
                    display: flex;
                    justify-content: center;
                    span{
                        margin: 0 2px;
                    }
                }
                li{
                    list-style-type: none;
                    float: left;
                    flex: 1;
                
                    .btn-record{
                        width: 52px;
                        height: 52px;
                        border-radius: 50%;
                        border: 2px solid #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        margin: auto;
                    }
                    .btn-play{
                        background-color: red;
                    }
                    .btn-stop{
                        color: red;
                    }
                    .btn-pause{
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        border: 1px solid #CCC;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        margin: auto;
                    }
                }
            }
        }

    }
}