.menuUser{
    background: url("../../../../../public/images/menu-user-bg.png");
    background-position: bottom;
    background-size: cover;
    .container-fluid{
        padding: 0 50px;
        .box-info{
            display: flex;
            .dropdown{
                button{
                    color: #FFFFFF;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    // line-height: 20px;
                    img{
                        border-radius: 50%;
                        width: 36px;
                        height: 36px;
                        margin-right: 20px;
                        border: 1px solid;
                    }
                }:active{
                    border: none;
                }
               
            }
            .dropdown-menu{
                margin-top: 10px;
                img{
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
            .vertical{
                // padding: 10px;
                // .vertical-line{
                    // border-left:2px solid #FFFFFF;
                    // margin:0 20px;
                // }
            }
            .notify{
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 23px;
                    height: 28px;
                    margin: 0 10px;
                }
            }
        }
    }
}