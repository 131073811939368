.question-select-answer{
    .list-answer{
        .dropdown .btn-group{
            width: 100%;
            .btn-select{
                width: 100%;
                color: #FFFFFF;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
                @media (min-resolution: 120dpi) {
                    border-color: #ccc;
                    color: #000;
                }
                i{
                    float: right;
                }
            }
            .dropdown-menu{
                width: 100%;
                background: rgba(255, 255, 255, 0.13);
                -webkit-backdrop-filter: blur(8.8px);
                backdrop-filter: blur(8.8px);
                .dropdown-item{
                    color: #FFFFFF;
                    text-wrap: balance;
                    @media (min-resolution: 120dpi) {
                        border-color: #000;
                        color: #000;
                    }
                }
                .dropdown-item:hover{
                    color: #000;
                }
                
            }
        }
        
    }
}