.assesment-details2{
   
    .row-header {
        display: flex;
        justify-content: center;
        align-items: center;
        .img-logo{
            width: 100px ;
        }
        .header-title{
            text-align: center;
            margin: 0;
            color: #00F0FF;
            text-transform: uppercase;
            font-size: 30px;
            font-weight: bold;
            margin-top: 10px;
        }
        .header-type{
            text-align: center;
            font-weight: 500;
            margin-top: 10px;
        }
        
    }
    .accordion{
        margin-top: 10px;
        .box-user{
            .col-md-6{
                p{
                    // text-align: center;
                }
            }
        }
    }    
    
    .row-by-skill{
        .skill-name{
            color: #00F0FF;
            font-weight: 500;
            font-size: 18px;
            text-decoration: underline;
        }
        .box-process{
            .process-header{
                display: flex;
                justify-content: space-between;
                .process-header-left{
                    color: #c3c3c3;
                }
            }
            .progress{
                margin-top: 10px;
                height: 8px;
                background-color: #000000d1;
                .progress-bar{
                    background-color: #00F0FF;
                    border-radius: 10px;
                }
            }
        }
        .skill-note{
            margin-top: 10px;
            color: #c3c3c3;
        }
    }
    a{
        color: #00F0FF;
    }
}