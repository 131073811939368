.singleChoice-review{
    margin-top: 30px;
    .question-content{
        img{
            max-width: 50%;
        }
    }
    .view-answerExams{
        color: #c3c3c3;
    }
    .answer-list{
        .form-check {
            margin: 10px 2px;
            .form-check-input{
                // background-color: initial;
                // height: 20px;
                // margin: 0;
                // width: 20px;
            }
            .form-check-input:checked{
                border: 3px solid;
                // border-color: #00F043;
                opacity: 1;
            }
            .form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label{
                opacity: unset;
            }
        }
    }
    .correct-true{
        color: #00F043;
        font-weight: bold;
    }
    .correct-false{
        color: #FB545C;
        font-weight: bold;
    }
}