.content-profile{
    background: url("../../../../public/images/profile.png");
    background-size: cover;
    background-position: bottom;
    // min-height: 100vh;
    min-height: 100vh;
    overflow-y: scroll;
    #profile{
        // padding-bottom: 80px;
        min-height: 100vh;
        .profile-left{
            min-height: 100vh;
            background: rgb(1 25 75 );
            padding: 0  0 0 40px;
            .card{
                background: none;
                border: none;
                .avata-box{
                    width: 100%;
                    height: 170px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .img-circle{
                        width: 131px;
                        height: 131px;
                        position: absolute;
                        border-radius: 50%;
                        border: 3px solid #00F0FF;
                        padding: 7px;
                        background: #FFFFFF;
                    }
                    .custom-file{
                        position: absolute;
                        margin-bottom: -11px;
                        background: #fff;
                        border-radius: 50%;
                        padding: 5px;
                        width: 26px;
                        height: 26px;
                        label{
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img{
                                height: 70%;
                            }
                        }
                    }
                    .custom-file:hover{
                        cursor: pointer;
                        background-color: #00F0FF;
                        img{
                            cursor: pointer;
                        }
                    }
                }
                .profile-username{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 26px;
                    margin-top: 20px;
                }
               
                .sidebar{
                    li {
                        list-style-type: none;
                        margin: 20px 0;
                        a{
                            color: #FFFFFF;
                            text-decoration: none;
                            // font-style: normal;
                            // font-weight: 700;
                            font-size: 16px;
                            img{
                                width: 24px;
                                height: 24px;
                                margin-right: 15px;
                            }
                        }:hover{
                            color: #00F0FF;
                            font-style: normal;
                            font-weight: 700;
                        }
                        
                    
                    }
                    .active{
                        color: #00F0FF;
                        font-style: normal;
                        font-weight: 700;
                        background: rgba(255, 255, 255, 0.15);
                        padding: 10px;
                        border-left: 2px solid #00F0FF;
                    }
                }
                .box-setting{
                    margin-top: 150px;
                }
            }
        
        }
    }
}