.ranking{
    margin: 80px 0 0 0;
    .title{
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */

        letter-spacing: 0.3px;
        text-transform: uppercase;
    }
    table, tr, td{
        border:none;
       }
    table{

        thead{
            background: #FFB800;
            height: 50px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
        }
        tbody{
            tr {
                height: 50px;
                td{
                    color: #FFFFFF;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    vertical-align:middle;
                    // text-align: center;
                    i{
                        margin-right: 15px;
                    }
                }
                td:nth-child(1){
                   padding-left: 15px;
                }
            }
           
            // tr:nth-child(odd) {
            //     background: rgba(255, 255, 255, 0.1);
            //     color: #FFFFFF;
            // }
            tr:nth-child(-n+3){
                background: rgba(255, 255, 255, 0.1);
                td:nth-child(1){
                    color: #FFB800;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 22px;
                }
            }
        }
    }
}