.speaking-review{
    margin-top: 30px;
    .view-answerExams{
        margin-top: 30px;
        
    }
    .question-content p:nth-child(n + 3) {
       color: #c3c3c3;
    }
    .correct-true{
        color: #00F043;
        font-weight: bold;
    }
}