.question{
    padding-top: 30px;
    @media (min-resolution: 120dpi) {
        padding-top: 10px;         
    }
    .question-name{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        @media (min-resolution: 120dpi) {
            font-size: 13px;               
        }
        img{
            width: 100%;
            height: 300px;
            object-fit: contain;
        }
    }
    .list-answer{
        ul{
            @media (min-resolution: 120dpi) {
                padding: 0;           
            }
            li{
            // list-style-type: upper-alpha;
            list-style-type: none;
            // margin:15px;
            .form-check {
                padding: 10px 8px;
                .form-check-input{
                    margin: 0;
                    width: 20px;
                    height: 20px;
                    // background-color: unset;
                    border: 1px solid #131318;
                    @media (max-width: 576px) {
                        border-color: #6c757d;        
                    }
                }
                .form-check-input:checked{
                    border: 3px solid;
                    border-color: #00F0FF;
                }
                .form-check-input:hover{
                    cursor: pointer;
                }
                .form-check-label{
                    padding-left: 10px;
                    display: flex;
                }
                // .form-check-label:hover{
                //     cursor: pointer;
                // }

                @media (min-resolution: 120dpi) {
                    .form-check-input{
                        margin: 0;
                        width: 20px;
                        height: 20px;
                        // background-color: unset;
                    }
                    .form-check-input:checked{
                        border: 3px solid;
                        border-color: #00F0FF;
                        background: url("../../../../../public/images/mb/checked.png");
                        background-size: contain;
                        background-repeat: no-repeat;                    
                    }
                    .form-check-input:hover{
                        cursor: pointer;
                    }
                    .form-check-label{
                        padding-left: 10px;
                        display: flex;
                    }
                    // .form-check-label:hover{
                    //     cursor: pointer;
                    // }         
                }
            }
            
          
            }
        }
    }
}