.welldone-light{
    height: calc(100vh - 80px);
    background: url("../../../public/images/light/bg-test.png");
    background-size: 100%;
    background-position: bottom;
    .wd-img{
        max-width: 402px ;
        max-height: 313;
        margin: auto;
    }
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 49px;
        color: #000;
        text-align: center;
    }
    .detail{
        margin-top: 10px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        @media (min-resolution: 120dpi) {
            margin-top: 0;     
        }
    }
    
    .form-info{
        // text-align: center;
        // @media (min-resolution: 120dpi) {
        //     margin-top: 0;     
        // }
        // .note{
        //     color: #ccc;
        //     span{
        //         color: #FFFFFF;
        //         font-weight: bold;
        //     }
        // }
    }
    .box-username{
        // padding-top: 15px;
        // margin: auto;     
        // @media (min-resolution: 120dpi) {
        //     padding-top: 15px;     
        // }       
        // input{
        //     height: 60px;
        //     border-radius: 15px;
        //     @media (min-resolution: 120dpi) {
        //         height: 40px;   
        //     } 
        // }
    }
    .btn-register{
        flex: 1;
        border-radius: 20px;
        margin: 5px;
        padding: 10px;
        background-color: #3060CF;
        color: #fff;
        align-items: center;
        justify-content: center;
        display: flex;
        @media (min-resolution: 120dpi) {
            width: 220px;
            height: 40px;  
            margin-top: 15px;
        } 
    }
    .btn-result{
        flex: 1;
        border-color: #3060CF;
        color: #3060CF;
        margin: 5px;
        padding: 10px;
        border-radius: 20px;
        // margin: 30px 5px;
        @media (min-resolution: 120dpi) {
            width: 220px;
            height: 40px;  
            margin-top: 15px;
        } 
    }
    // .note{
    //     margin-top: 20px;
    //     span{
    //         color: #3060CF;
    //         font-weight: bold;
    //     }
    // }
}