.voucher-modal{
   .modal-dialog{    
        .modal-content{
            background: none;           
            .voucher-gif{
                width: 163px;
                height: 163px;
                margin: auto;
            }
            .title{
                // font-family: SVN-Gilroy;
                font-size: 24px;
                font-weight: 600;
                line-height: 30px;
                text-align: center;
                color: #000;
            }
            .sub{
                color: #000;
                // font-family: SVN-Gilroy;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                text-align: center;
                margin: 0;
            }
            .voucher-value{
                color: #3060CF;
                font-size: 30px;
                font-weight: bold;
                text-align: center;
                text-transform: uppercase;
                margin-top: 10px;
            }
            .btn-basic,.btn-advance{
                border-radius: 20px;
                font-size: 16px;
                font-weight: 600;
                line-height: 18px;
                text-align: center;
                padding: 15px;
            }
            .btn-basic{
                color: #000;
            }:hover{
                color: #3060CF ;
            }
            .btn-advance{
                color: #fff;
                background-color: #3060CF !important;
                border: none;
            }:hover{
                color: #000 !important;
            }
        }       
       
   }
   @media (max-width:576px) {
    .modal-dialog{
        .modal-content{
            padding: 20px !important;
            .title{
                font-size: 16px;
            }
            .sub{
                font-size: 14px;
            }
            .voucher-value{
                margin-top: 10px;
                font-size: 25px;
            }
            .btn-basic,.btn-advance{
                font-size: 14px !important;
            }
        }
    }

   }
}