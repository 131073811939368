.completing{
    background: url("../../../public/images/completing-bg.png");
    background-position: bottom;
    background-size: cover;
    height: 100vh;
    overflow-y: scroll;
    // .intro {
    //     height: calc(100% - 80px);
        .container{
            height: calc(100% - 80px);
            .heigh-light{
                width: 100%;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 24px;
                color: #00F0FF;
                text-align: center;
            }
            .guide{
                font-family: 'Montserrat';
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                span{
                    color: #00F0FF;
                }
            }
            .guide-qr{
                margin-top: 20px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            }
            .complating-qr{
                width: 252px;
                height: 252px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .back-to-home{
                margin-top: 50px;
                .btn-back-to-home{
                    width: 273px;
                    height: 64px;
                    background: #00F0FF;
                    border-radius: 150px;
                    display: flex;
                    justify-content: center;
                    align-items: center;                
                    p{
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        color: #000000;
                        margin: 0;
                        user-select: none;
                    }
                }:hover{
                    cursor: pointer;
                }
                
            }
            .auto-back-to-home{
                margin-top: 15px;
                p{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    user-select: none;
                }
            }
        }
        
    // }
    
}