.assesment-by-id{
    background: url("../../../../../public/images/profile.png");
    background-size: cover;
    background-position: bottom;
    min-height: 100vh;
    .skill-detail{

        .skill-type{
            margin: 20px 0;
            .skill-header{
                display: flex;
                .header-title {
                    flex: 3;
                    text-align: center;
                    p{                                               
                        font-family: Montserrat;
                        font-size: 28px;
                        font-weight: 600;
                        letter-spacing: 0em;
                        padding: 0;
                        margin: 0;
                        }
                    i{
                        font-size: 14px;
                    }
                    
                   
                }
                
                .boder-bottom{
                    flex: 2;
                    display: flex;
                    align-items: center;
                    .progress{
                        height: 2px;
                        width: 100%;
                        .progress-bar{
                            background-color: #fff;
                        }
                    }
                //     border-bottom:2px solid #FFFFFF ;
                }
            }
        }
    }
    .description tbody, td, tfoot, th, thead, tr{
        color: #fff ;
    }
    .session-title{
        color: #00F0FF;
        max-height: 40vh;
        overflow-y: auto;
    }
    .session-title::-webkit-scrollbar {
        width: 6px;
    }
    .session-title::-webkit-scrollbar-thumb {
        background: #00F0FF; 
        border-radius: 10px;
      }
      /* Track */
    .session-title::-webkit-scrollbar-track {
        background: #ccc; 
    }
}