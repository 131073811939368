.result-analysis-ai{
    background: url("../../../public/images/result-analysis-bg.png");
    background-position: center;
    background-size: cover;
    height: 100vh;
    overflow-y: scroll;
    @media (max-width:576px) {
        background: url("../../../public/images/mb/result.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
    .container{
        padding-bottom: 100px;
        .box-score{
            display: flex;
            .score-circle{
                flex: 1;
                @media (max-width:576px) {
                    flex: 2;
                }
            }
            .score-skill{
                flex: 5;
                padding:0 20px;   
                @media (max-width:576px) {
                    flex: 6;
                }             
                .skill-name{
                    border-bottom: 1px solid #CCCCCC;
                    p{
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 29px;
                        @media (max-width:576px) {
                            font-size: 18px;
                            margin: 0;
                        }
                    }
                }
                .skill-score-detail{
                    padding: 10px 0;
                    @media (max-width:576px) {
                        font-size: 13px;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
        .box-result-overview{
            background: rgba(255, 255, 255, 0.13);
            border: 1px solid rgba(255, 255, 255, 0.18);
            backdrop-filter: blur(8.8px);
            padding: 25px;
            .title{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                text-align: justify;
                color: #FFFFFF;
                @media (max-width:576px) {
                    font-size: 18px;
                }
            }
            .guide{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                text-align: justify;
                color: #FFFFFF;
                @media (max-width:576px) {
                    font-size: 14px;
                }
            }
            
        }
        .box-speaking-test{
            background: url("../../../public/images/result-analysis-box-speaking-bg.png");
            background-position: center;
            background-size: cover;
            margin-top: 40px;
            padding: 30px;
            .title{
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
            }
            .upgreade{
                margin: 0;
                color: #00F0FF;
            }
            .experience{
                color: #00F0FF;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 26px;
            }
            .guide{
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                text-align: justify;
                margin: 0;
                width: 50%;
            }
        }
        .freemium{
            flex: 1;
            padding:0;
            margin: 40px 10px 0 0;
            .box-freemium{        
                background: linear-gradient(146.38deg, #9070FE 2.76%, #27ABEF 102.65%);
                border-radius: 20px;            
                padding: 30px;
                .freemium-head{
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    text-align: center;
        
                    color: #F7ED00;
                }
                .freemium-content{
                    ul {
                        padding: 0;
                        margin-top: 25px;
                        li{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            color: #FFFFFF;
                            margin-bottom: 5px;
                            list-style: none;
                            display: flex;
                            img{
                                width: 25px;
                                height: 25px;
                            }
                            p{
                                padding-left: 12px;
                            }
                        }                
                    }
                }
                .freemium-footer{
                    .btn-pulse{
                        width: 228px;
                        height: 64px;
                        left: calc(50% - 228px/2 + 0.5px);
                        top: 476px;
        
                        background: #FFFFFF;
                        border: 2px solid #00F0FF;
                        border-radius: 50px;
                        color: #000000;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        animation: pulse 1s infinite;
                        transition: .3s;
                        user-select: none; 
                        @keyframes pulse {
                            0% {
                            transform: scale(1);
                            }
                            70% {
                            transform: scale(.9);
                            }
                            100% {
                            transform: scale(1);
                            }
                        }
                        p{
                            text-transform: uppercase;
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 22px;
                            color: #000000;
                            margin: 0;
                        }
                    }
                    .btn-pulse:hover{
                        cursor: pointer;
                    }
                }
            }
        
        }
        .guest-user{
            flex: 1;
            padding:0;
            margin: 40px 0 0 10px;
            .box-guest-user{
                // background: rgba(255, 255, 255, 0.13);
                // border: 1px solid rgba(255, 255, 255, 0.18);
                // backdrop-filter: blur(8.8px);
                // border-radius: 20px;
                
                padding: 30px;
                .guest-user-head{
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    text-align: center;

                    color: #FFFFFF;
                }
                .guest-user-content{
                    ul {
                        padding: 0;
                        margin-top: 25px;
                        li{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            color: #FFFFFF;
                            margin-bottom: 5px;
                            list-style: none;
                            display: flex;
                            img{
                                width: 25px;
                                height: 25px;
                            }
                            p{
                                padding-left: 12px;
                            }
                        }                
                    }
                    .box-username{
                        height: 64px;
                        input{
                            border-radius: 15px;
                        }
                        
                    }
                }
                .guest-user-footer{
                    margin-top: 40px;
                    .btn-pulse{
                        width: 228px;
                        height: 64px;
                        border: 1px solid #FFFFFF;
                        border-radius: 50px;
                        color: #000000;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        p{
                            text-transform: uppercase;
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 22px;
                            color: #FFFFFF;
                            margin: 0;
                        }
                    }
                    .btn-pulse:hover{
                        cursor: pointer;
                    }
                }
            }
        }
        .box-result-analysis{
            background: rgba(255, 255, 255, 0.13);
            border: 1px solid rgba(255, 255, 255, 0.18);
            backdrop-filter: blur(8.8px);
            padding: 25px;
            @media (max-width: 576px) {
               font-size: 14px;
               background: #FFFFFF;
               margin: 10px;
               padding: 10px;
               border-radius: 10px;
            }  
            .analysis-header{
                display: flex;
                @media (max-width: 576px) {
                  padding: 0;
                }  
                .header-title{
                    flex: 1;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 29px;
                    text-align: justify;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    @media (max-width: 576px) {
                        flex: 3;
                        p{
                            font-size: 18px;
                            line-height: 30px;
                            margin: 0;
                            padding: 0;
                            color: #000000;
                        }
                    }   
                }
                .header-list-skill{
                    flex: 2;
                     
                    .list-skill{
                        border: 1px solid #FFFFFF;
                        border-radius: 50px;
                        width: 80%;
                        height: 40px;
                        ul {
                            display: flex;
                            margin: 0;
                            padding: 0;
                            height: 100%;
                            li {
                                flex: 1;
                                list-style-type: none;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                p{
                                    margin: 0;
                                    @media (max-width: 576px) {
                                        width: 70px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        text-wrap: nowrap;
                                        margin: 0;
                                    }                                      
                                }
                            }:hover{
                                cursor: pointer;
                                background: #00F0FF;
                                border-radius: 50px;
                                color: #000000;
                            }
                            .skill-active{
                                background: #00F0FF;
                                border-radius: 50px;
                                color: #000000;
                                @media (max-width: 576px) {
                                    background: #3060CF;
                                    color: #FFF;
                                    font-size: 12px;
                                }          
                            }
                        }
                    }
                    @media (max-width: 576px) {
                        flex: 2;
                        .list-skill{
                            width: 100%;
                        }
                    } 
                }
            }
            
            
        }
        .analysis-content{
            display: flex;
            margin-top: 15px;
            @media (max-width: 576px) {
                display: block;
            }
            .score-skill{
                flex: 1;
                @media (max-width: 576px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    .score-skill-title{
                        flex: 1;
                        color: #000000;
                        font-size: 14px;
                    }
                    .score-skill-circle{
                        width: 51px;
                        flex:1;
                        padding:0 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .CircularProgressbar{
                            width: 65px;
                        }
                    }
                } 
                .score-skill-title{
                    padding: 15px 0;
                    // text-align: center;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                }
                .score-skill-circle{
                    padding: 30px 80px 0 0  ;
                }
                
            }
            .detail-score-skill{
                flex: 3;
                padding: 15px 0;
                .score-skill-head{
                    display: flex;
                    width: 80%;
                    margin: 10px 0;

                }
                .progress{
                    width: 80%;
                    background-color: #060B24;
                    height: 14px;
                    .progress-bar{
                        background-color: #FFFFFF;
                        border-radius: 10px;
                    }
                }
                .score-analysis{
                    margin-top: 50px;
                    @media (max-width: 576px) {
                        margin-top: 0px !important;
                    }
                    .score-analysis-title{
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        @media (max-width: 576px) {
                           color: #000000;
                           font-size: 14px;
                        }
                    }
                    .score-analysis-detail{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: #ccc;
                        @media (max-width: 576px) {
                            max-height: 30vh;
                            overflow: auto;
                            color: #000000;
                        }  
                    }
                }
            }
        }
        .performance-analysis{
            margin-top: 90px;
            @media (max-width: 576px) {
                margin-top: 30px;
            }  
            .performance-analysis-header{
                display: flex;
                padding: 0;
                .progess-custom{
                    flex: 2;
                    display: grid;
                    align-items: center;
                    .progress{
                        height: 2px;
                        .progress-bar{
                            background-color: #FFFFFF;
                            @media (max-width: 576px) {
                                background-color: #000;
                            }  
                        }
                    }
                }
                .performance-analysis-title{
                    flex: 10;
                    text-align: center;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 39px;
                    text-transform: uppercase;
                    @media (max-width: 576px) {
                        font-size: 20px;
                        color: #000000;
                        font-size: 14px;
                        line-height: 20px;
                    }  
                    
                }
               
            }
            .date-custom{
                @media (max-width:576px) {
                    color: #000;
                }
            }
            p{
                @media (max-width: 576px) {
                    font-size: 12px;
                }  
            }
            .performance-left{
                audio{
                    width: 100%;
                }
                .box-performance-left{
                    @media (max-width: 576px) {
                       background-color: #fff;
                       padding: 15px;
                       border-radius: 15px;
                    } 
                    .box-performance{
                        padding: 25px;
                        border: 0.5px solid rgba(255, 255, 255, 0.5);
                        backdrop-filter: blur(8.6px);
                        border-radius: 5px;
                        .color-primary{
                            color: #00F0FF;
                        }
                        @media (max-width: 576px) {
                            padding:0;
                            color: #000;
                        } 
                    }
                }
            }
            .performance-right{
                padding-left: 20px;
                padding-right: 0;
                .box-performance-right{
                    height: 100%;
                    background: #0E2A47;
                    backdrop-filter: blur(8.8px);
                    padding: 20px;
                   
                }
            }
          
        }
    }    
}
.accordion-box{
    margin: 30px 0 0 0;
    padding: 0;
    .accordion {
        .accordion-item{
            margin-top: 10px;
            border: none;
            background:none ;
            .accordion-header{
                .accordion-button{
                    background: #031D39;
                    color: #FFFFFF;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    @media (max-width: 576px) {
                        background: #fff;
                        color: #000;
                    } 
                }
                .accordion-button::after{
                    position: absolute;
                    right: 10px;
                    background-image: var(--bs-accordion-btn-active-icon);
                }
            }
            .accordion-body{
                background: #031D394D;
                color: #FFFFFF;
                .title{
                    color: #00F0FF;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: justify;
                    text-underline-offset: 10px;
                    text-decoration: underline;
                    text-decoration-color: #fff;
                }   
                .box-performancePro{
                    display: flex;
                    border-radius: 10px;
                    padding: 0;
                    .circle-point{
                        flex: 1;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                  
                    .performancePro-right{
                        flex: 4;
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                       
                        .content{
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: justify;
                            color:#FE9400;
                            padding-top: 10px;
                        }
                        .content-lexical{
                            color: #00B7F0;
                        }
                    }
                }
                .words-list{
                   margin: 15px;
                }
                .box-performancePro-skill {
                    padding: 0;
                    margin-top: 20px;
                    .skill-box{
                        margin-top: 25px;
                        .skill-title {
                            display: flex;
                            align-items: center;
                            .title-text{
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 20px;
                                letter-spacing: 0em;
                            }
                            .title-note{
                                padding: 3px 8px;
                                border-radius: 50px;
                                background: #FB545C;
                                margin-left: 15px;
                                font-size: 15px;
                            }
                        }
                        .tutorial{
                            margin-top: 10px;
                            padding: 10px;
                            background: #060B24;
                            border-radius: 10px;
                            min-height: 100px;
                            display: flex;
                            .tutorial-video{
                                flex: 1;
                                img{
                                    width: 100%;
                                }
                            }
                            .tutorial-right{
                                padding-left: 10px;
                                flex: 2;
                                display: flex;
                                align-items: center;
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 20px;
                                letter-spacing: 0em;

                            }
                        }
                    }
                }
                .performance-right-list-skill{
                    flex: 2;
                    .list-skill{
                        border: 1px solid #FFFFFF;
                        border-radius: 50px;    
                        height: 40px;
                        ul {
                            display: flex;
                            margin: 0;
                            padding: 0;
                            height: 100%;
                            li {
                                flex: 1;
                                list-style-type: none;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                p{
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    margin: 0;
                                    width: 80%;
                                }
                            }:hover{
                                cursor: pointer;
                                background: #00F0FF;
                                border-radius: 50px;
                                color: #000000;
                            }
                            .skill-active{
                                background: #00F0FF;
                                border-radius: 50px;
                                color: #000000;
                            }
                        }
                    }
                }
                .performance-pro{
                    .box-word{
                        background: #F4F7FF;
                        border-radius: 5px;
                        width: fit-content;
                        height: auto;
                        float: left;
                        margin: 5px;
                        padding: 5px;
                        .title{
                            color: #335CBA;
                            // font-family: 'Inter';
                            // font-style: normal;
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 30px;
                        }
                        .box-word-detai{
                            width: 23px;
                            float: left;
                            margin: 3px;
                            .alphabet{
                                background-color: red;
                                text-align: center;
                                width: 100%;
                            }
                            .color1{
                                background-color: #589F43;
                            }
                            .color2{
                                background-color: #F1BF3A;
                            }
                            .color3{
                                background-color: #F0776E;

                            }
                            .percen{
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 30px;
                                text-align: center;
                                color: #000000;
                            }
                        }
                    }
                }
                .performance-gluency{
                    .accordion{
                        .accordion-item{
                            margin-top: 10px;
                            background:none;
                            border: none;
                            .accordion-header{
                                background: #F6F8FF;
                                box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
                                border-radius: 5px;
                                ul{
                                    padding: 0;
                                    margin: 0;
                                    li{
                                        list-style-type: none;
                                        float: left;
                                        margin:0 10px;
                                        font-weight: bold;
                                        i{
                                            color: #597FE1;
                                        }
                                        .progress{
                                            width: 50px;
                                            height: 2px;
                                            margin-top: 10px;
                                            .progress-bar{
                                                background-color: #000000;
                                            }
                                        }
                                    }
                                    .color-primary{
                                        color: #597FE1;
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 24px;
                                    }
                                }
                            }
                            #panelsStayOpen-collapseOne{
                                .accordion-body{
                                    display: flex;
                                    padding: 18px;
                                    .pause-body{
                                        flex: 1;
                                        p{
                                            text-align: center;
                                            margin: 0;
                                        }
                                        .title{
                                            font-size: 14px;
                                            line-height: 17px;
                                            color: #FFFFFF;
                                            
                                        }
                                        .detail{
                                            font-family: 'Inter';
                                            font-style: normal;
                                            font-weight: bold;
                                            font-size: 16px;
                                            line-height: 19px;
                                            color: #FFFFFF;
                                        }
                                    }                                        
                                }
                            }
                            #panelsStayOpen-collapseTwo,#panelsStayOpen-collapseThree,#panelsStayOpen-collapseFour{
                                .title{
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }
                }
                
               
                
                .performanceGluency{
                    .performanceGluency-box{
                        margin-top: 30px;
                        .content{
                            margin-top: 20px;
                            span{
                                color: #00F043;
                                
                            }
                        }
                    }
                    
                }
                .performanceLexical{
                    margin-top: 20px;
                    padding: 0;
                    .lexical-box{
                        margin-top: 20px;
                        .content{
                            margin-top: 20px;
                            ul{
                                padding: 0;
                                display: inline-flex;
                                flex-wrap: wrap;
                                li{
                                    border: 1px solid #fff;
                                    border-radius: 5px;
                                    padding: 3px 5px;
                                    list-style-type: none;
                                    margin: 10px 10px 0 0;
                                }
                            }
                            .lists-cefr{
                                max-width: 70%;
                                margin-top: 30px;
                                .ceft-items{
                                    display: flex;
                                    margin: 15px 0;
                                    .item{
                                        flex: 1;
                                        border:1px solid #0030AD;
                                        border-radius:50%;
                                        min-width: 32px;
                                        min-height: 32px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 14px;
                                        font-weight: 700;

                                    }
                                    .itemC2,.progress-bar-C2{
                                        background-color: #0030AD;
                                    }    
                                    .itemC1,.progress-bar-C1{
                                        background-color: #0057BD;
                                    }      
                                    .itemB2,.progress-bar-B2{
                                        background-color: #0496D4;
                                    }    
                                    .itemB1,.progress-bar-B1{
                                        background-color: #2CB3ED;
                                    }      
                                    .itemA2,.progress-bar-A2{
                                        background-color: #53CEF4;
                                    }    
                                    .itemA1,.progress-bar-A1{
                                        background-color: #8EE4FF;
                                    }                                       
                                    .progress{
                                        flex: 9;
                                        height: 32px;
                                        margin-left: 10px;
                                        background: rgba(6, 11, 36, 0.5);
                                        .percent{
                                            padding-left: 10px;
                                            line-height: 32px;
                                            margin: 0;
                                            font-size: 14px;
                                            font-weight: 600;

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .performanceDelivery{
                    .speed{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 20px;
                        flex-direction: column;
                        .speed-volume{
                           width: 150px;
                           height: 90px;
                           position: relative;
                           .speed-volume-png{
                                position: absolute;
                           }
                           .speed-retate-png{
                                bottom: 5px;
                                margin-left: 30px;
                                transition: all 2s ease-in-out;
                                transform-origin: 94% 48%;
                                position: absolute;
                           }
                        }
                        .detai-speed{
                            width: 100%;
                            ul {
                                display: flex;
                                padding: 0;
                                justify-content: space-between;
                                li{
                                    list-style-type: none;
                                    margin-right: 10px;
                                    display: flex;
                                    align-items: center;
                                    font-size: 14px;
                                    
                                    .circle{
                                        width: 12px;
                                        height: 12px;
                                        background: #FEBB50;
                                        border-radius: 50%;
                                        display: block;
                                        margin-right: 5px;
                                    }
                                    .circle-2{
                                        background: #43A2FF;
                                    }
                                    .circle-3{
                                        background: #FB545C;
                                    }
                                }
                            }
                        }
                    }
                    .chart{
                        width: 100%;margin-bottom: 30px;
                    }
                    .speaking-shows{
                        padding-left: 30px;
                        border-left: 3px solid #B9B9B9;
                    }
                }
                .performanceIntonation{
                    // display: flex;
                    // flex-direction: column;
                    padding: 20px 30px;
                    .intonation-box{
                        width: auto;
                        padding: 20px;
                        background: #060B24;
                        border-radius: 10px;
                        margin-bottom: 20px;
                        .intonation-title{
                            font-size: 16px;
                            line-height: 20px;
                            letter-spacing: 0em;                                   
                        }
                        .pronounce{
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0em;
                            margin: 10px 0;      
                            padding: 10px 0px;                                
                        }
                        .media{
                            ul{
                                margin: 0;
                                padding: 0;
                                li{
                                    list-style-type: none;
                                    float: left;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}