#welcome{
    height: 100vh;
    background-color: #060B24;
    .intro{
        height: calc(100vh - 80px);
        background: url("../../../public/images/welcome-bg.png");
        background-size: cover;
        background-position: bottom;
        .title{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 49px;
            color: #FFFFFF;
            text-align: center;
            span{
                color: #00F0FF;
            }
        }
        .detail{
            margin-top: 15px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
            text-align: center;

        }
        .box-username{
            padding-top: 30px;
            width: 70%;
            margin: auto;            
            input{
                height: 60px;
                border-radius: 20px 0 0 20px;
            }
            .input-group-append{
                height: 60px;
                .btn-get-started{
                    height: 100%;
                    background: #00F0FF;
                    border-radius: 0 20px 20px 0;
                   
                    padding: 10px;
                    p{
                        font-family: "Montserrat";
                        color: #000000;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 40px;       
                    }
                }
                .btn-get-started:hover{
                    cursor: pointer;
                }
            }
            
        }
        .rocket{
            margin-top: 50px;
        }
        .btn-get-started{
            margin: auto;
            width: 189px;
            height: 75px;
            background: #00F0FF;
            border-radius: 20px;
           
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                font-family: "Montserrat";
                color: #000000;
                font-weight: bold;
                font-size: 18px;
                line-height: 40px;
                margin: 0;   
            }
        }
        .btn-get-started:hover{
            cursor: pointer;
        }
    }
    .btn-group{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}