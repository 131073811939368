@media (max-width:576px){
    #homepage{
        background: url("../../../public/images/mb/bg-home.png");
        background-size: cover;
        .header{
            min-height: 50vh;
            background: url("../../../public/images/mb/bg-home-head.png");
            background-size: cover;
            .top{
                max-height: 10vh;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px;               
                .btn-login{
                    box-shadow: 0px 4px 10px 0px rgba(0, 44, 59, 0.08);
                    padding: 12px 24px;
                    background-color: #fff;
                    border-radius: 30px;
                    color: #3060CF;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;

                }
                .avatar{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: #3060CF ;
                }
            }
            .content-header{
                min-height: 40vh;
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: center;
                flex-direction: column;
                .highlight-title{
                    font-size: 25px;
                    font-weight: 800;
                    line-height: 31.5px;
                    text-align: center;
                }
                .sub-title{
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 18.9px;
                    text-align: center;

                }
            }
        }
        .content{
            padding: 15px;
            .ct-menu{
                position: sticky;
                top: 0;
                background-color: #F5F8FF;
                z-index: 9;
                .list-menu{
                    display: flex;
                    justify-content: space-around;
                    padding: 10px 0;
                    .menu-item{
                        a{
                            color: #131318;
                            font-size: 15px;
                            font-weight: 600;
                            line-height: 16.8px;
                            text-align: center;
                            text-decoration: none;
                        }
                        .active{
                            color: #3060CF;
                        }
                    }
                }
            }
           
            .about-us{
                background-color: unset;
                padding: 15px 0;
                .about-us-title{
                    font-size: 26px;
                    font-weight: bold;
                    line-height: 27.3px;
                    text-align: center;
                    color: #131318;
                    text-transform: uppercase;
                }
                .list-card{
                    // padding: 0 15px;
                    margin-top: 20px;
                    .row-card{
                        display: flex;
                        column-gap: 10px;
                        grid-template-columns: auto auto;
                        margin-top: 40px;
                        .box-card{
                            flex: 1;
                            display: flex;
                            justify-content: center;
                            a{
                                text-decoration: none;
                            }
                            .card{
                                background-color: #fff;
                                border-radius: 15px;
                                border: none;
                                padding: 10px;
                                .card-body{
                                    padding: 0;
                                    .card-text{
                                        color: #131318;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                        .box-card1  {
                            justify-content: center;
                            align-items: center;
                            .card{
                                width: 80%;
                                align-items: center;
                                justify-items: center;
                               
                            }

                        }
                        .box-card2 {
                            justify-content: end;
                            align-items: end;
                            .card{
                                width: 95%;
                                align-items: end;
                                justify-content: right;
                                bottom: -20px;
                            }
                        }
                    }
                    
                }
            }
            .input-test{
                padding-top: 100px;
                .input-test-title{
                    font-size: 26px;
                    font-weight: bold;
                    line-height: 27.3px;
                    text-align: center;
                    color: #131318;
                    text-transform: uppercase;
                }
                .list-test{
                    margin-top: 20px;
                    .card{
                        margin: 20px 0;
                        .card-body{
                            padding: 0;
                        }
                        padding: 15px;
                        border-radius: 30px;
                        border: none;
                        .card-top{
                            display: flex;
                            .top-left{
                                flex: 1;
                                display: flex;
                                align-items: center;
                                img{
                                    width: 100%;
                                    padding: 10px;
                                    border-radius: 50%;
                                    border: 2px solid #3060CF;
                                }
                            }
                            .top-right{
                                flex: 5;
                                padding-left: 20px;
                                .top-right-title{
                                    color: #161F38;
                                    font-size: 16px;
                                    font-weight: 700;
                                    line-height: 24px;
                                    text-align: start;
                                    margin: 0;
                                }
                                .top-right-guide{
                                    color: #B0B1B2;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 18px;
                                    text-align: left;
                                    margin: 10px 0;
                                }
                            }
                        }
                        .card-bottom{
                            display: grid;
                            column-gap: 10px;
                            grid-template-columns: auto auto;
                            
                        }
                        .card-bottom,.card-bottom1{
                            margin-top: 15px;
                        }
                        .btn-test{
                            border: 1px solid #3060CF;
                            border-radius: 20px;
                            color: #3060CF;
                            padding: 10px;
                            text-align: center;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 22px;
                        }
                        .btn-full-test{
                            background-color: #3060CF;
                            color: #fff;
                            width: 100%;
                        }
                    }
                }                
            }
            .contact{
                min-height: 100vh;
                padding-top: 100px;                
                .box-contact{  
                    padding: 20px;
                    background: #fff;
                    border-radius: 30px;              
                    .contact-title{
                        font-size: 24px;
                        font-weight: bold;
                        line-height: 27.3px;
                        text-align: left;
                        color: #131318;
                        text-transform: uppercase;
                    }
                    .contact-title-sub{
                        margin-top: 10px;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 13.65px;
                        color: #131318;
                        margin-bottom: 30px;
                    }
                    .input-row{
                        label{
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 14.7px;
                            letter-spacing: 0.0015em;
                            text-align: left;
                            color: #131318;
                        }
                        input{
                            margin-top: 5px;
                            padding: 10px;
                        }
                        input::placeholder {
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 14.7px;
                            letter-spacing: 0.005em;
                            text-align: left;

                        }
                        .btn-submit{
                            background-color: #3060CF;
                            color: #fff;
                            width: 100%;
                            border: 1px solid #3060CF;
                            border-radius: 20px;
                            padding: 10px;
                            text-align: center;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
        #speakingModal{
            .modal-content{
                padding: 20px 40px;
                .modal-title{
                    color: #000;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 26px;
                    letter-spacing: 0em;

                }
                .btn-basic{
                    border-color: #636363;
                    color: #636363;
                    font-size: 18px;
                    font-weight: 600;
                }
                .btn-basic:hover{
                    background-color: #FFFFFF;
                    color: #00B7F0;
                }
                .btn-advance{
                    background-color: #00B7F0;
                    border-color: #00B7F0;
                    color: #FFFFFF;
                    font-size: 18px;
                    font-weight: 600;
                }
                .btn-advance:hover{
                    color: #636363;
                }
            }
        }
    }
}