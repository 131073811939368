.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}

.embla__slide {
  flex: 0 0 30px;
  min-width: 0;
  transform: translate3d(0, 0, 0);
}
.embla__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.questions-pallete {
  gap: 10px;
  .question-pallete-item {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #3060cf;
    }
  }
}

.arrow-button {
  cursor: pointer;

  &:hover {
    background-color: #3060cf;
  }
}
