@media (max-width:576px) {
    .loginpageMB{
        margin: 0;
        padding: 0;
        background: url("../../../../public/images/mb/welcomemb.png");        
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100vh;
        .box-img{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            img{
                width: 231px;
            }
        }
        .form-login{
            padding: 0 20px;
            .input-group{
                span{
                    color: #9F9F9F;
                }
                input{
                    padding: 10px;
                    margin: 0;
                }
                input:focus {
                    border: 1px solid #d5d4dc;
                    box-shadow: none;
                }
                .input-group-text{
                    background: #fff;
                }
                .border-l-none{
                    border-left:none ;
                }
                .border-r-none{
                    border-right: none;
                }
                .input-group-text:first-child{
                    border-right: none;
                }
                .form-check-label{
                    color: #131318;
                    padding-left: 10px;
                }
                .btn-auth{
                    width: 100%;
                    background-color: #3060CF;
                    color: #fff;
                    border-radius: 30px;
                    padding: 10px;
                }
            }
            .register{
                p{
                    color: #131318;
                }
            }
            .continue{
                display: flex;
                justify-content: center;
                align-items: center;
                .continue-item{
                    flex: 1;
                   
                }
                .progress{
                    height: 1px;
                    .progress-bar{
                        background-color: #131318;
                    }
                }
                .continue-item2{
                    flex: 2;
                    color: #131318;
                    text-align: center;
                }
            }
            .continue-social{
                display: grid;
                grid-template-columns: auto auto;
                gap: 10px;
                padding-bottom: 20px;
                .social{
                    background: #fff;
                    border-radius: 15px;
                    padding: 15px;
                }
                .social-fb{
                    color: #1778F2;
                }
                .social-gg{
                    color: red;
                }
            }
        }
    }
}