@media (min-resolution: 120dpi) {
    // .active{
    //     background-color: #fff;
    //     border: 2px solid #fff;
    //     color: #000000 !important;
    //     .content{
    //         color: #000000 !important;
    //     }
    // }
    .part-passed {
        margin: 15px;
        background: #fff;
        border-radius: 5px;
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .passed-text{
            color: #000;
            font-size: 13.5px;
            margin: 5px 0 0 0;
        }
        img {
            width: 22px;
            height: 22px;
    
        }
    }
}