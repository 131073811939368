.wc-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  min-height: 8vh;
  height: auto;
  padding: 15px;
  position: sticky;
  top: 0;
  background-color: #f5f8ff;
  z-index: 9999;

  .header-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .wc-back {
      flex: 1;

      .btn-back {
        background-color: #00000005;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 30px;
        width: 36px;
        height: 36px;

        i {
          color: #272727;
        }
      }
    }

    .wc-title {
      color: #272727;
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      text-align: center;
      flex: 2;
      text-transform: capitalize;
    }

    .wc-next {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .btn-next {
        background: #0085ff;
        border-radius: 10px;
        padding: 8px;
        text-align: center;
      }

      #submitModal,
      #nextModal {
        .modal-content {
          padding: 20px;

          .row-content {
            display: flex;
            justify-content: center;
            color: #000;
          }

          img {
            width: 60%;
          }

          .btn-submit {
            background-color: #0085ff;
            color: #fff;
          }

          .btn {
            border-radius: 50px;
            font-size: 14px;
            font-weight: 600;
          }

          .btn-basic {
            color: #000;
          }
        }
      }
    }
  }

  .extend {
    display: flex;
    margin-top: 10px;

    .clock {
      flex: 1;
    }

    .acction-extend {
      flex: 1;
      color: #000;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      i {
        margin-left: 10px;
      }
    }

    .list_question {
      li {
        width: 30px;
        height: 30px;
        margin: 6px;
        border: 1px solid #000;
        border-radius: 50%;
        list-style-type: none;
        float: left;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 29px;
        text-align: center;

        @media (min-resolution: 120dpi) {
          font-size: 12px;
          line-height: 25px;
          width: 25px;
          height: 25px;
        }

        a {
          color: #000;
          text-decoration: none;
          display: block;
        }
      }

      .answered {
        background-color: #0085ff;

        border-color: #0085ff;

        a {
          color: #fff;
        }
      }
    }

    .extend-opend {
      .answered-detail {
        color: #000;

        ul li {
          list-style-type: none;
          float: left;
          margin-right: 10px;

          .circle {
            width: 15px;
            height: 15px;
            border: 1px solid #000;
            border-radius: 50%;
            display: inline-block;
            margin: 0 5px;
          }

          .circle-answered {
            background-color: #0085ff;
            border: none;
          }
        }
      }

      .acction-extend {
        justify-content: center;
      }
    }
  }
}
