.footer{
    background-color: #080E28;
    min-height: 20vh;
    padding: 50px 0;
    // @media (max-width: 576px) {
    //     height:auto;
    // }
    // i{
    //     color: #FFFFFF;
    // }
    // .container{
    //     @media (max-width: 576px) {
    //         padding: 30px 0;
    //         height:auto;
    //     }
    // }
    // .logo-footer{
    //     border-right: 1px solid #FFFFFF;
    //     border-radius: unset;
    //     padding: 0 30px 0 0;
    //     margin-right: 30px !important;
    //     @media (max-width: 576px) {
    //         border: none;
    //         width: 100%;
    //         padding: 0;
    //     }
    // }
    .container-fluid{
        .head{
            .box-progress{
                display: flex;
                align-items: center;
               
                .progress{
                    height: 1px;
                    width: 100%;
                    .progress-bar{
                        background-color: #FFFFFF;
                    }
                }
            }
            .title p{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 37px;
                margin: 0;
            }
        }
        .row-info{
            .btn-info{
                width: 250px;
                height: 48px;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-decoration-line: underline;
                color: #000000;
                background: #00F0FF;
                border-radius: 4px;
            }
        }
        .row-phone{
            p{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
            }
        }
        .row-list-icon{
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                li{
                    list-style-type: none;
                    float: left;
                    margin:0 20px;
                    border-radius: 50%;
                    border: 1px solid #FFFFFF;
                    width: 48px;
                    height: 48px; 
                    @media (max-width: 576px) {
                        width: 38px;
                        height: 38px; 
                        margin:0 12px;

                    }                 
                    a{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;
                        i{
                            color: #FFFFFF;
                        }
                    }
                    
                }:hover{
                    border-color:  #00F0FF;
                    i{
                        color:  #00F0FF;;
                    }
                }
            }
        }
        .row-logo{
            img{
                width: 140px;
                margin: 0;
            }
        }
        .info-company{
            @media (max-width: 576px) {
                display: none;

            }   
            .location{
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: start;
                i{
                    margin-right: 10px;
                }
            }
            .location-map{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                /* identical to box height */

                text-align: start;
                text-decoration-line: underline;

                color: #00F0FF;
            }
        }
    }
}