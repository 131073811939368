.countdown {
  min-width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .box-countdown {
    display: flex;
    width: 100%;
    .icon-clock {
      flex: 1;
      color: #fff;
      @media (max-width: 576px) {
        color: #000;
      }
    }
    .count-time {
      flex: 2;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      @media (max-width: 576px) {
        font-size: 14px;
        padding: 0 5px;
        color: #000;
      }
    }
  }
}

.text-3060CF {
  color: #3060cf;
}

.text-00f0ff {
  color: #00f0ff;
}
