.writing{
    .wrapper-content-writing{
        // overflow: unset;
    }
    .writing-detail{
        margin: 30px 0;
        color: #ccc;
        b{
            color: #FFFFFF;
        }
        @media (max-width: 576px) {
            color: #000;
            font-size: 14px;
        }    
    }
    .box-passage{
        width: 100%;
        margin-top: 30px;
        .item-passage{
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 576px) {
                color: #000;
            }   
            .item-icon{
                width: 30px;
                height: 30px;
                background: rgba(255, 255, 255, 0.1);
                border: 0.5px solid rgba(255, 255, 255, 0.1);
                color:  rgba(255, 255, 255, 0.1);;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                @media (max-width: 576px) {
                    color: #ccc;
                }   
            }
            .item-icon:hover{
                cursor: not-allowed;
            }
            .item-icon-active{
                color: #FFFFFF;
                @media (max-width: 576px) {
                    color: #000;
                }    
            }
            .item-icon-active:hover{
                cursor: pointer !important;
            }
       }
    }
    .ielts-writing-head{
        @media (max-width: 576px) {
            height: 40vh;
            overflow: scroll;
        }    
        .writing-guide{
            color: #ccc;
            
        }
        .writing-detail{
            p img{
                width: 100%;
            }
        }
        
    }
    .box-writing-text{
        padding-top: 30px;
        height: 100%;
        @media (max-width: 576px) {
            min-height: 40vh;
            overflow: scroll;
            
            border-radius: 20px;
            padding: 0;
        }   
        .form-group{
            // height: 100%;
            height: calc(100% - 60px);
            background: rgba(6, 6, 6, 0.1);
            border: 0.5px solid rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            @media (max-width: 576px) {
                min-height: 100%;
                background: #FFFFFF;
                color: #000;
            }   
            textarea{
                min-height:calc(100% - 30px);
                width: 100%;
                background: none;
                // backdrop-filter: blur(8.6px);
                // border-radius: 5px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                text-align: justify;
                padding: 25px;
                // color: #FFFFFF;
                border: none;
                @media (max-width: 576px) {
                    color: #000;
                    font-size: 14px;
                }   
            }
            textarea::placeholder {
                opacity: 0.5;
                // color: #FFFFFF;
            }
            textarea::-webkit-scrollbar {
                width: 6px;
            }
            textarea::-webkit-scrollbar-track {
                background: rgba(217, 217, 217, 0.3);
            }
            textarea::-webkit-scrollbar-thumb {
                // background: #FFFFFF; 
                border-radius: 10px;
            }
            textarea:focus {
                outline: none !important;
                border: none !important;
                box-shadow:none
            }
            .count-number{
                text-align: end;
                padding-right: 14px;
                // margin-top: -30px;
                // font-family: 'Nunito';
                font-style: italic;
                font-weight: 300;
                font-size: 14px;
                line-height: 19px;
            }
        }
       
    }
}