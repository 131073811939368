.mycourse{
    padding: 50px;
    .title{
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 30px;
    }
    img{
        width: 468px;
        height: 468px;
        margin: auto;
    }
    .skill{
        height: 50px;
        border: 1px solid #FFFFFF;
        border-radius: 150px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
    }
    .skill:hover{
        cursor: pointer;
        color: #000000 ;
        background-color: #00F0FF;
    }
}
