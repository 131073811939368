.pointer {
  cursor: pointer;
}

.hover-bg-3060cf {
  &:hover {
    background-color: #3060cf;
  }
}

.hover-border-3060cf {
  &:hover {
    border: 1px solid #3060cf;
  }
}

.hover-color-3060cf {
  &:hover {
    color: #3060cf;
  }
}

.hover-bg-white {
  &:hover {
    background-color: white;
  }
}
