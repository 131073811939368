@media (min-resolution: 120dpi) {
    .welcome-mb,.welcome-mb1{               
        height: 100vh;
        .wc-content{
            // height: 92vh;
            // background-color: red;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            color: #272722;
            .wc-title{
                font-size: 35px;
                font-weight: bold;
                line-height: 45px;
                text-align: left;
            }
            .title{
                    color: #161F38;
                    // font-family: SVN-Gilroy;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: center;
            }
            .detail{
                    margin-top: 10px;
                    color: #161F38;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: center;
            }
            .detail1{
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
            }
            .img-wc{
                width: 300px;
                height: 300px;
            }
            .list-skill{            
                margin-top: 20px;
                .card{
                    width: 49%;
                    background: none;
                    border: none;
                    float: left;
                    img{
                        width: 55%;
                        margin-left: 16px;
                    }
                    .card-body{
                        .card-title{
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 24px;
                        }
                        .card-text{
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;

                        }
                    }
                }
            }
            .input-group{
                margin-top: 10px;
                input{
                    border-radius: 10px;
                    height: 50px;                
                }
                button{
                    background-color: #0085FF;
                    width: 100%;
                    padding: 12px 24px 12px 24px;
                    border-radius: 40px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    color: #FFFFFF;
                }
            }
        }
    }
    .welcome-mb{
        background:url("../../../public/images/mb/welcomemb.png");
        background-repeat: round;
    }
    .welcome-mb1{
        background:url("../../../public/images/mb/welcomemb1.png");       
        background-repeat: round;
    }
}