
.box-visualize{
    #visualizeMic{
        max-height: 100px;
        background: #00F0FF;
    }
    margin-bottom: 20px;
}
#CheckMedia{
    .modal-header{
        .modal-title{
            color: #000;
        }
    }
    .modal-body{
        .box-actions{
            display: flex;
            justify-content: center;
            .btn-start-stop{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #00B7F0;
                // color: #FFF;
                // font-size: 18px;
                // font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    width: 15px;
                    fill: #FFF !important;
                }
            }
            .btn-start-stop:hover{
                cursor: pointer;
                background-color: #0c8bb3;
            }
        }
        .box-result{
            margin-top: 10px;
            max-height: 300px;
            overflow: auto;
            display: grid;
            justify-content: center;
            audio{
               margin: 5px 0;
            }
        }
    }
}