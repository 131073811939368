.assesment-history{
    .title{
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
    }
    .list-box{
        display: flex;
        justify-content: space-between;
        .box-ave,.box-att,.box-bes,.box-cur{
            
            flex: 1;
        }
        .box-week{
            flex: 3;
            // display: flex;
            // flex-direction: column;            
        }
        .box-detail{
            width: auto;
            margin: 5px;
            // min-width: 155px;
            padding: 15px;
            background: rgba(255, 255, 255, 0.15);
            box-shadow: inset 1px 0px 5px rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            // margin-right: 15px;
            .box-title{
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                
            }
            .box-score{
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 37px;
                letter-spacing: 0.3px;
                color: #00F0FF;
                span{
                    font-size: 20px;
                }
            }
            .list-skill{
                .box-detail-small{
                    width: 25%;
                    padding:0 10px;
                    border-right: 1px solid #FFFFFF;
                    float: left;
                   .box-title{
                    @media (min-resolution: 120dpi) {
                        font-size: 12px;   
                    }
                   }
                }
                .box-detail-small:last-child{
                    border: none;
                    padding: 0 0 0 10px;

                }
                .box-detail-small:first-child{
                    padding: 0;
                }
                // .box-detail-small{
                //     border: none;
                // }
                
            }
        }
    }
    .performance-history{
        margin: 50px 0 0 0;
        .title{
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            /* identical to box height */

            letter-spacing: 0.3px;
            text-transform: uppercase;
        }
        table, tr, td{
            border:none;
           }
        table{

            thead{
                background: #00F0FF;
                height: 50px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
            }
            tbody{
                tr {
                    height: 50px;
                    td{
                        color: #FFFFFF;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        vertical-align:middle;
                        // text-align: center;
                        i{
                            margin-right: 15px;
                        }:hover{
                            cursor: pointer;
                            color: #00F0FF;
                        }
                    }
                }
               
                tr:nth-child(odd) {
                    background: rgba(255, 255, 255, 0.1);
                    color: #FFFFFF;
                }
            }
        }
    }
    .performance-select{
        .select-row{
            display: inline-flex;
            .btn-select{
                width: 70px;
            }
            p{
                line-height: 40px;
                margin-left: 20px;
            }
        }
        .box-pagination{
            display: contents;
            .paginate {
                margin: 0;
                padding: 0;
                li {
                    list-style-type: none;
                    float: left;
                    margin: 0 3px;
                    .page-num{
                        background: rgba(255, 255, 255, 0.2);
                        border: 1px solid rgba(255, 255, 255, 0.2);
                        border-radius: 4px;
                        color: #FFFFFF;
                        padding: 10px 18px;
                        text-decoration: none;
                    }
                    .active{
                        background-color: #00F0FF;
                        color: #000000;
                    }
                }
            }
           
            
        }
    }
   
}