.fillTheBlankReView-review{
    margin-top: 30px;
    .question-content{
        img{
            max-width: 50%;
        }
    }
    .view-answerExams{
        color: #c3c3c3;
    }
    .correct-true{
        color: #00F043;
        font-weight: bold;
    }
    .correct-false{
        color: #FB545C;
        font-weight: bold;
    }
}