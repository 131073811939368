.homepage {
  padding: 0;
  margin: 0;

  .edtech {
    background-color: #080e28;
    // min-height: 100vh;
    padding-top: 90px;

    @media (max-width: 576px) {
      padding-top: 40px;
      height: auto;
    }

    @media (min-resolution: 120dpi) {
      height: auto;
    }

    .container {
      padding: 0;
    }

    .header-edtech {
      // height: 15vh;
      @media (max-width: 576px) {
        // height: 10vh;
      }

      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        text-align: justify;
        color: #ffffff;
        text-shadow: 0px 0px 20px rgba(0, 240, 255, 0.7);

        @media (max-width: 576px) {
          font-weight: 600;
          font-size: 22px;
          line-height: 27px;
        }
      }
    }

    .content-edtech {
      // height: 70vh;
      padding-top: 40px;

      @media (min-resolution: 120dpi) {
        // height:105vh ;
      }

      @media (max-width: 576px) {
        height: auto;
      }

      .content-left {
        margin: 0;
        padding: 0 80px 0 0;
        border-right: 1px solid #ffffff;

        @media (max-width: 576px) {
          padding: 0 30px;
        }

        .card {
          background: none;

          @media (max-width: 576px) {
            padding-bottom: 30px;
            border-bottom: 1px solid #ffffff;
            border-radius: unset;
          }

          .card-body {
            color: #ffffff;
            padding: 0;

            .card-title {
              font-weight: 700;
              font-size: 28px;
              line-height: 34px;

              @media (max-width: 576px) {
                font-size: 16px;
                line-height: 20px;
              }

              @media (min-resolution: 120dpi) {
                font-size: 22px;
              }
            }

            .card-text {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              padding: 25px 0;

              @media (max-width: 576px) {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                padding: 15px 0;
              }
            }
          }
        }
      }

      .content-right {
        margin: 0;
        padding-left: 80px;

        @media (max-width: 576px) {
          padding: 30px;
        }

        .card {
          background: none;
          color: #ffffff;
          height: 50%;

          @media (max-width: 576px) {
            height: auto;
          }

          .card-body {
            color: #ffffff;
            padding: 0 50px 0 0;

            @media (max-width: 576px) {
              padding: 0;
            }

            .card-title {
              font-weight: 700;
              font-size: 28px;
              line-height: 34px;

              @media (max-width: 576px) {
                font-size: 16px;
                line-height: 20px;
              }

              @media (min-resolution: 120dpi) {
                font-size: 20px;
              }
            }

            .card-text {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              padding: 40px 0;

              @media (max-width: 576px) {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                padding: 10px 0 15px 0;
              }
            }
          }
        }

        .card1 {
          border-bottom: 1px solid #ffffff;
          border-radius: unset;

          @media (max-width: 576px) {
            padding-bottom: 30px;

            .col-md-4 .img-fluid {
              min-width: 100%;
            }
          }
        }

        .card2 {
          justify-content: flex-end;

          @media (max-width: 576px) {
            padding-top: 30px;
          }
        }
      }
    }

    .footer-edtech {
      // height: 10vh;
      margin: 0;
      padding: 0;

      @media (max-width: 576px) {
        height: auto;
      }

      img {
        width: 54px;
        text-align: center;

        @media (max-width: 576px) {
          display: none;
        }
      }
    }
  }

  .antedu {
    background-color: #080e28;
    padding-top: 90px;

    // min-height: 100vh;
    @media (min-resolution: 120dpi) {
      // height:130vh ;
      height: auto;
    }

    @media (max-width: 576px) {
      padding-top: 40px;
      height: auto;
    }

    .header-antedu {
      // height: 15vh;
      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        text-align: justify;
        color: #ffffff;
        text-shadow: 0px 0px 20px rgba(0, 240, 255, 0.7);

        @media (max-width: 576px) {
          font-weight: 600;
          font-size: 22px;
          line-height: 30px;
          text-align: center;
        }
      }
    }

    .content-antedu {
      height: 85vh;
      display: flex;
      position: relative;

      @media (min-resolution: 120dpi) {
        align-items: center;
        justify-content: center;
      }

      @media (max-width: 576px) {
        height: auto;
      }

      .card-img-bg {
        position: absolute;
        height: 100%;
      }

      .antedu-box {
        width: 100%;
        height: 100%;
        z-index: 9;

        .row-list {
          height: 30%;
          display: flex;

          @media (min-resolution: 120dpi) {
            scale: 0.8;
          }

          .box-list {
            display: flex;
            padding: 0;

            .box-content {
              flex: 5;
              margin-top: 30px;

              .box-content-title {
                max-height: 45px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                background-image: url("../../../public/images/line-antedu.png");
                background-size: cover;
                padding: 5px 0;
                width: 100%;
                background-position: bottom right;
                font-weight: bold;
                font-size: 18px;
                overflow: auto;
                text-transform: uppercase;
              }

              .box-content-content {
                height: calc(100% - 75px);

                ul {
                  padding: 10px 14px;
                  display: none;
                  position: absolute;

                  li {
                    list-style-type: auto;
                    text-transform: capitalize;
                  }
                }
              }
            }

            .box-icon {
              flex: 1;

              img {
                width: 50px;
                position: unset;
              }
            }
          }

          .box-list:hover {
            .box-content-title {
              color: #00f0ff;
              background-image: url("../../../public/images/line-antedu-hover.png");
            }

            .box-content-content {
              ul {
                display: block;
              }
            }
          }
        }

        .row-list-first {
          align-items: center;
          justify-content: center;
        }

        .row-list-two {
          height: 30%;
          justify-content: space-around;
          align-items: center;
        }

        .row-list-three {
        }
      }
    }
  }

  .ielts-list {
    background-color: #080e28;
    padding-top: 90px;

    // min-height: 70vh;
    @media (max-width: 576px) {
      padding-top: 40px;
      min-height: auto;
    }

    .container {
      display: flex;

      .ielts-list-left {
        flex: 1;

        @media (max-width: 576px) {
          padding: 0 30px;
        }

        .left-head {
          .title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            color: #ffffff;
            text-shadow: 0px 0px 20px rgba(0, 240, 255, 0.7);

            @media (max-width: 576px) {
              font-weight: 600;
              font-size: 22px;
              line-height: 60px;
              text-align: center;
            }
          }
        }

        .left-content .list-core {
          padding: 30px 0;

          @media (max-width: 576px) {
            padding: 10px 0;
          }

          .box-core[aria-expanded="true"] {
            background-color: red;
          }

          .box-core {
            color: #ffffff;
            padding: 20px 0;

            @media (max-width: 576px) {
              padding: 5px 0;
            }

            .title-score:hover {
              cursor: pointer;
            }

            .title-score::before {
              content: url("../../../public/images/mui-ten-ngang.svg");
              // transition: transform 2s ease;
              // // transform-origin: 0.5em 50%;
              padding-right: 30px;
            }

            .title-score[aria-expanded="true"]::before {
              content: url("../../../public/images/mui-ten-xuong.svg");
            }

            .title-score[aria-expanded="true"] {
              color: #00f0ff;
            }

            .title-score {
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 34px;
              text-align: justify;

              @media (max-width: 576px) {
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
              }
            }

            .item-score {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              padding-left: 50px;

              @media (max-width: 576px) {
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
              }
            }
          }
        }
      }

      .ielts-list-right {
        flex: 1;

        @media (max-width: 576px) {
          display: none;
        }
      }
    }
  }

  .about-us {
    background-color: #080e28;
    padding: 90px 0;

    @media (max-width: 576px) {
      padding-top: 40px;
      min-height: auto;
    }

    .container {
      padding: 0;
    }

    .header-about-us {
      // height: 15vh;
      @media (max-width: 576px) {
        height: auto;
      }

      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        text-align: justify;
        color: #ffffff;
        text-shadow: 0px 0px 20px rgba(0, 240, 255, 0.7);

        @media (max-width: 576px) {
          font-weight: 600;
          font-size: 22px;
          line-height: 60px;
        }
      }
    }

    .content-about-us {
      padding-top: 40px;

      @media (max-width: 576px) {
        padding: 0;
      }

      .react-multi-carousel-list {
        .react-multi-carousel-track {
          .card {
            background: none;
            border: none;
            border-radius: unset;

            a {
              color: #ffffff;
            }

            :hover {
              color: #00f0ff;
            }
          }
        }
      }
    }
  }
}

#speakingModal {
  .modal-content {
    padding: 20px 40px;
    background-color: #ffffff;

    .modal-title {
      color: #000;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0em;
    }

    .btn-basic {
      border-color: #636363;
      color: #636363;
      font-size: 18px;
      font-weight: 600;
    }

    .btn-basic:hover {
      background-color: #ffffff;
      color: #00b7f0;
    }

    .btn-advance {
      background-color: #00b7f0;
      border-color: #00b7f0;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
    }

    .btn-advance:hover {
      color: #636363;
    }
  }
}
