.assesment-details{
    .accordion{
        .accordion-item{
            background: none;
            color: #FFF;
            .accordion-header .accordion-button{
                text-transform: uppercase;
                font-weight: bold;
                font-size: 25px;
                background-color: #FFF;
                color: #00f0ff;
            }
            .accordion-collapse .accordion-body .session {
                .session-title p{
                    // font-size: 25px;
                    // color: #00f0ff;
                }
                .group-question{
                    padding-left: 25px;
                    .table{
                        color: #FFF;
                    }
                    .question-box{
                        padding-bottom: 20px;
                        .question-name{
                            font-size: 20px;
                            // color: #00f0ff;
                            img{
                                width: 100%;
                                height: 300px;
                                object-fit: contain;
                            }
                        }
                        .table tbody tr {
                            display: flex;
                            td,th {
                                flex: 1;
                                color: #FFF;
                                .answer-true{
                                    color: green;
                                    font-size: 20px;
                                    margin-left: 10px;
                                }
                                .answer-false{
                                    color: red;
                                    font-size: 20px;
                                    margin-left: 10px;
                                }
                            }
                        }
                        
                        
                    }
                    
                }
            }
        }
    }
}