#menuWCLight {
  height: 80px;
  box-shadow: 0px 4px 20px 0px #00000026;
  // position: sticky;
  // position: absolute;
  background-color: #3060cf;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 9999;
  padding: 0 100px;

  .container-fluid .main-logo {
    flex: 1;

    .img-logo {
      width: 40%;
    }
  }

  .list-item-menu {
    flex: 6;
    // justify-content: space-around;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;

    .nav-item {
      padding: 0 15px;
      margin: 0 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;

      @media (min-resolution: 120dpi) {
        font-size: 14px;
      }

      .nav-link {
        color: #ffffff;
        text-transform: uppercase;

        @media (min-resolution: 120dpi) {
          font-size: 12px;
        }
      }

      .dropdown-menu {
        background: none;
        border: none;
        padding-top: 0px;
      }
    }

    .nav-item:hover {
      // font-size: 20px;
      // color: #00B7F0;
      text-decoration: underline;
      text-underline-offset: 10px;
      text-decoration-thickness: 3px;
      transition: all 0.5s ease;
    }

    .dropdown .dropdown-menu {
      // display: block;
      left: -50%;

      li {
        margin-top: 5px;
        border-radius: 50px;
        border: 1px solid #ffffff;
        padding: 5px 20px;
        background-color: #060b24;

        a {
          color: #ffffff;
          font-weight: 500;
        }

        a:hover {
          color: #000000;
          font-weight: 500;
        }
      }

      li:hover {
        background-color: #ffffff;
      }
    }

    .dropdown:hover .dropdown-menu {
      display: block;
    }
  }

  .language {
    flex: 2;
    display: flex;

    .box-user-info {
      img {
        border-radius: 50%;
      }
    }

    .box-info {
      flex: 3;
      display: flex;

      .navbar-nav {
        width: 100%;

        .nav-item {
          font-family: "Montserrat";
          padding: 5px 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;

          @media (min-resolution: 120dpi) {
            font-size: 10px;
            padding: 0;
          }

          .nav-link {
            color: #ffffff;

            @media screen and (max-width: 1366px) {
              padding: 5px 10px !important;
              font-size: 12px;
            }

            @media (min-resolution: 120dpi) {
              padding: 3px;
              font-size: 10px;
            }
          }
        }

        .nav-item:last-child {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 30px;

          .nav-link {
            color: #5490f5;
          }
        }
      }

      button {
        // border-radius: 8px;
        // border: 1px solid #FFFFFF;
        // padding: 10px 20px 12px 20px;
        border: none;
        color: #ffffff;

        @media (min-resolution: 120dpi) {
          padding: 4px 8px 5px 8px;
        }

        img {
          border-radius: 50%;

          @media (min-resolution: 120dpi) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .box-language {
      flex: 2;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      button {
        @media (min-resolution: 120dpi) {
          padding: 4px 8px 5px 8px;
        }

        img {
          border-radius: 50%;

          @media (min-resolution: 120dpi) {
            width: 20px;
            height: 20px;
          }
        }
      }

      :checked,
      :active {
        border: none;
      }

      .dropdown-menu {
        @media (max-width: 576px) {
          min-width: 58px;
        }

        li {
          padding: 0 10px;

          img {
            border-radius: 50%;

            @media (min-resolution: 120dpi) {
              width: 20px;
              height: 20px;
            }
          }
        }

        :hover {
          cursor: pointer;
        }
      }
    }
  }
}
