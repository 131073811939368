.write-answer{
    .list-answer{
        input{
            background: none;
            border: none;
            // border-bottom:1px dotted #FFFFFF ;
            // border-radius: unset;
            color: #FFFFFF;
            border-radius: unset;
            @media (min-resolution: 120dpi) {
                // background: #FFFFFF;             
                border-radius: 10px;
                color: #000;
            }
        }:focus{
            box-shadow: none;
            // color: #FFFFFF;
            @media (min-resolution: 120dpi) {                
                color: #000;
            }
        }::placeholder{
            // color: #cccccc6b;
            font-style: italic;
            @media (min-resolution: 120dpi) {                
                font-size: 12px;
            }
        }
    }
}