#welldone{
    height: 100vh;
    background-color: #060B24;
    .intro{
        height: calc(100vh - 80px);
        background: url("../../../public/images/welldone-bg.png");
        background-size: 100%;
        background-position: bottom;
        position: relative;
        .box-head{
            height: 20vh;
            .title{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 49px;
                color: #FFFFFF;
                text-align: center;
                @media (min-resolution: 120dpi) {
                    font-size: 32px;            
                }
                span{
                    color: #00F0FF;
                }
            }
            .detail{
                margin-top: 10px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
                text-align: center;
                @media (min-resolution: 120dpi) {
                    margin-top: 0;     
                }
            }
        }
        .box-content{
            height: calc(80vh - 80px);
           
            .box-content-detail{
                height: 100%;
                background: url('../../../public/images/welldone-content-bg.png') ;
                background-size: contain;
                background-position: bottom;
                background-repeat: no-repeat;
                .form-info{
                    text-align: center;
                    @media (min-resolution: 120dpi) {
                        margin-top: 0;     
                    }
                    .note{
                        color: #ccc;
                        span{
                            color: #FFFFFF;
                            font-weight: bold;
                        }
                    }
                }
                .box-username{
                    padding-top: 15px;
                    margin: auto;     
                    @media (min-resolution: 120dpi) {
                        padding-top: 15px;     
                    }       
                    input{
                        height: 60px;
                        border-radius: 15px;
                        @media (min-resolution: 120dpi) {
                            height: 40px;   
                        } 
                    }
                }
                .btn-completed{
                    width: 228px;
                    height: 64px;
                    background: #00F0FF;
                    border-radius: 15px;
                    margin: 30px 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    @media (min-resolution: 120dpi) {
                        width: 220px;
                        height: 40px;  
                        margin-top: 15px;
                    } 
                }
                .btn-back{
                    width: 228px;
                    height: 64px;
                    // background: #00F0FF;
                    border-color: #FFFFFF;
                    color: #FFFFFF;
                    border-radius: 15px;
                    margin: 30px 5px;
                    @media (min-resolution: 120dpi) {
                        width: 220px;
                        height: 40px;  
                        margin-top: 15px;
                    } 
                }
                .btn-loading{
                    color: #000;
                    .text-light{
                        color: #000 !important;
                    }
                }
            }
        }
        .box-phao-hoa{
            img{
                width: 100%;
            }
        }
    }
    
}