.reading{
    .wrapper-content-reading{
        overflow: unset;
    }
    .box-passage{
        width: 100%;
        margin-top: 30px;
        @media (min-resolution: 120dpi) {
            color: #000;
        }
        .item-passage{
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            .item-icon{
                width: 30px;
                height: 30px;
                background: rgba(255, 255, 255, 0.1);
                border: 0.5px solid rgba(255, 255, 255, 0.1);
                color:  rgba(255, 255, 255, 0.1);;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                @media (min-resolution: 120dpi) {
                    color: #ccc;
                }
            }
            .item-icon:hover{
                cursor: not-allowed;
            }
            .item-icon-active{
                color: #000;
            }
            .item-icon-active:hover{
                cursor: pointer !important;
            }
       }
    }
    .box-read-text{
        height: calc(100vh - 310px);
        overflow-y: scroll;
        margin-top: 30px;
        margin-bottom: 30px;
        background: rgba(255, 255, 255, 0.1);
        border: 0.5px solid rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(8.6px);
        border-radius: 5px;
        @media (min-resolution: 120dpi) {
           height: 40vh;
           background: #fff;
           border-radius: 20px;
        }
        .ielts-wrapper-head{
            padding:25px;
            
        }
        .ielts-wrapper-content{
            padding: 0;
        }
    }
    .box-read-text::-webkit-scrollbar {
        width: 6px;
    }
    .box-read-text::-webkit-scrollbar-thumb {
        background: #00F0FF; 
        border-radius: 10px;
      }
      /* Track */
    .box-read-text::-webkit-scrollbar-track {
        background: #ccc; 
    }
    
    .box-read-question{
        padding: 0 25px;
        height: calc(100vh - 220px);
        overflow-y: scroll;
        @media (min-resolution: 120dpi) {
            font-size: 14px;               
        }
    }
    .box-read-question::-webkit-scrollbar {
        width: 8px;
    }
    .box-read-question::-webkit-scrollbar-thumb {
        background: #00F0FF; 
        border-radius: 10px;
      }
      /* Track */
    .box-read-question::-webkit-scrollbar-track {
        background: #FFFFFF; 
    }
    
}