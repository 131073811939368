
#menuietls{
    height: 80px;
    background-size: cover;
    position: absolute;   
    width: 100%;
      .main-logo{
        flex: 2;
      }
      .navbarsItem{
        flex: 5;
        .navbarsItemList{
          width: 100%;
          .navbar-nav{
            width: 100%;
            .nav-item {
              // width: 15%;
              padding: 0 15px;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
              :hover{
                text-decoration: underline #FFFFFF;
                text-underline-offset: 10px;
                text-decoration-thickness: 3px;
                // padding: 0 13px;
                transition: all 0.5s ease;
              }
              @media (min-resolution: 120dpi) {
                font-size: 14px;
              }
              .nav-link{
                color: #FFFFFF;
                text-transform: capitalize;
              }
            }
            .active {
              text-decoration: underline #FFFFFF;
              text-underline-offset: 10px;
              text-decoration-thickness: 3px;
              // padding: 0 13px;
              transition: all 0.5s ease;
            }
          }
        } 
      }
      .action-ielts{
        flex: 5;
        ul {
          padding: 0;
          margin: 0;
          display: flex;
            align-items: center;
          li {
            list-style-type: none;
            float: left;
            margin-right: 30px;
          }
        }
        
        .box-btn-submit {
            border: 1px solid #FFFFFF;
            border-radius: 10px;
            @media (min-resolution: 120dpi) {
              height: 41px;
            }
            .btn-submit{
                width:160px  ;
                padding: 6px;
                color: #FFFFFF;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 30px;
                text-align: center;
                @media (min-resolution: 120dpi) {
                  font-size: 14px;
                  // width: auto;
                  line-height: 30px;
                }
                p{
                  line-height: 32px;
                }
                i{
                    width: 25px;
                    height: 26px;
                    margin-right: 10px;
                    transform: rotate(20deg);
                    @media (min-resolution: 120dpi) {
                      width: 20px;
                      height: 20px;
                    }
                }                
            }
            .btn-submit:hover{
                cursor: pointer;
                color: #000000;
            }
        }
        .box-btn-submit:hover{
          background-color: #00F0FF;
        }
        .box-review{
            color: #FFFFFF;
            text-align: center;
            margin: auto;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            i{
              margin-right: 8px;
            }
        }
        .box-review:hover{
          cursor: pointer;
        }
      }
}
.background-drark{
  background: linear-gradient(0, rgba(22, 31, 56, 0) 0%, rgba(0, 240, 255, 0.36) 127.5%);
}
.background-light{
  background-color: #3060CF;
}
#submission{
  .modal-header{
    color: #000000;
  }
  .modal-body{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #000000;
  }
  .modal-footer{
    justify-content: center;
    .btn-submission{
      justify-content: center;
      align-items: center;
      padding: 6px 26px;
      gap: 10px;
      width: 100px;
      height: 34px;
      border-radius: 50px;
    }
    .btn-submission-yes{
      background-color: #00F0FF;
      color: #000000;
    }
    .btn-submission-no{
      border: 1px solid #ccc;
    }
    .btn-submission-no:hover{
      color: #000000;
    }
  }
}   
    