.content-ielts {
  min-height: 100vh;
  background: #033164;
  @media (max-width: 576px) {
    background: url("../../../public/images/mb/listen-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .content-ielts-wrapper {
    padding-top: 80px;
    min-height: calc(100vh - 80px);
    min-height: 100vh;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    @media (max-width: 576px) {
      padding-top: 0;
      min-height: 100vh;
    }
  }
}
.wrapper-content {
  height: calc(100vh - 190px);
  overflow-y: scroll;
  @media (max-width: 576px) {
    height: auto;
  }
  .ielts-wrapper-head {
    padding-top: 30px;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      // color: #00F0FF;
      @media (max-width: 576px) {
        color: #0085ff;
      }
    }
    .sub-title {
      color: #00f0ff;
    }
    .detail {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
    @media (max-width: 576px) {
      p {
        padding: 0;
        margin: 0;
      }
    }
  }
  .ielts-wrapper-content {
    padding-left: 20px;
    padding-bottom: 50px;
    @media (max-width: 576px) {
      color: #000000;
    }
    .box-content-head {
      .title {
        //   color: #00F0FF;
        // figure[class='table'] table td strong{
        //     color: #ffffff;
        // }
        // figure[class='table'] table {
        //     font-family: Arial, Helvetica, sans-serif;
        //     border-collapse: collapse;
        //     width: 100%;
        //     color: #ffffff9e;
        // }

        // figure[class='table'] table td, figure[class='table'] table th {
        //     border: 1px solid #ddd;
        //     padding: 8px;
        // }

        // figure[class='table'] table th {
        //     padding-top: 12px;
        //     padding-bottom: 12px;
        //     text-align: left;
        //     background-color: #000;
        //     color: #000;
        // }
        @media (max-width: 576px) {
          figure[class="table"] table td strong {
            color: #000;
          }
          figure[class="table"] table {
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
            color: #000;
          }

          figure[class="table"] table td,
          figure[class="table"] table th {
            border: 1px solid #000;
            padding: 8px;
          }

          figure[class="table"] table th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #000;
            color: #000;
          }
        }
        p img {
          width: 100%;
        }
      }
      .sub-title {
        color: #00f0ff;
      }
    }
    .ielts-wrapper-content-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: justify;
      padding: 0 25px 25px 25px;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
  }
}
// .wrapper-content::-webkit-scrollbar {
//     width: 6px;
// }
.wrapper-content::-webkit-scrollbar {
  width: 10px;
}
.wrapper-content::-webkit-scrollbar-thumb {
  background: #00f0ff;
  border-radius: 10px;
}
/* Track */
.wrapper-content::-webkit-scrollbar-track {
  background: #ffffff;
}
.ielts-wrapper-footer {
  height: 110px;
  .list_question {
    li {
      width: 30px;
      height: 30px;
      margin: 6px;
      border-radius: 50%;
      list-style-type: none;
      float: left;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 29px;
      text-align: center;
      @media (min-resolution: 120dpi) {
        font-size: 12px;
        line-height: 25px;
        width: 25px;
        height: 25px;
      }
      a {
        // color: #FFFFFF;
        text-decoration: none;
        display: block;
      }
    }
    // .answered {
    //     background-color: #00F0FF;
    //     border-color: #00F0FF;
    //     a{
    //         color: #000000;
    //     }
    // }
  }
  .btn-next-skill,
  .btn-next-skill-light {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    i {
      padding-left: 10px;
      line-height: 20px;
    }
  }
  .btn-next-skill:hover {
    color: #00f0ff;
  }
  .btn-next-skill-light:hover {
    color: #3060cf;
  }
  .guide-question li {
    list-style-type: none;
    float: left;
    margin-right: 10px;
    .circle {
      width: 15px;
      height: 15px;
      // border: 1px solid #FFFFFF;
      border-radius: 50%;
      display: inline-block;
      margin: 0 5px;
    }
    .circle-answered {
      // background-color: #00F0FF;
      border: none;
    }
  }
}
.short-test-mb {
  background: url("../../../public/images/mb/listen-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.color-black {
  color: #000000;
}
.color-white {
  color: #ffffff;
}
.color-3060CF {
  color: #3060cf;
}
.color-00F0FF {
  color: #00f0ff;
}
.color-ccc {
  color: #ccc;
}
.background-black {
  background: #000000;
}
.background-white {
  background: #ffffff;
}
.background-footer-dark {
  background: rgba(255, 255, 255, 0.13);
  border: 0.5px solid rgba(255, 255, 255, 0.18);
  backdrop-filter: blur(8.8px);
}
.background-3060CF {
  background: #3060cf;
}
.background-2BAED1 {
  background: #2baed1;
}
.background-00F0FF {
  background: #00f0ff;
}
.background-ccc {
  background: #ccc;
}
.border-black {
  border: 1px solid #000 !important;
}
.border-bottom-black {
  border-bottom: 1px solid #000 !important;
  border-radius: unset;
}
.border-white {
  border: 1px solid #ffffff;
}
.border-bottom-white {
  border-bottom: 1px solid #ffffff !important;
}
.border-3060CF {
  border: 1px solid #3060cf !important;
}
.border-2BAED1 {
  border: 1px solid #2baed1 !important;
}
.border-ccc {
  border: 1px solid #ccc !important;
}
.border-00F0FF {
  border: 2px solid #00f0ff;
}
.placeholder-dark::placeholder {
  color: #cccccc6b;
}
.placeholder-light::placeholder {
  color: #000;
}

.dark-mode {
  figure[class="table"] table td strong {
    color: #ffffff;
  }
  figure[class="table"] table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    color: #ffffff9e;
  }

  figure[class="table"] table td,
  figure[class="table"] table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  figure[class="table"] table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #000;
    color: #000;
  }

  .question-name,
  .ielts-writing-head {
    span,
    p {
      color: #ffffff !important;
    }
  }
  .list-answer {
    color: #ffffff !important;
  }
}

.light-mode {
  figure[class="table"] table td strong {
    color: #000;
  }
  figure[class="table"] table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    color: #000;
  }

  figure[class="table"] table td,
  figure[class="table"] table th {
    border: 1px solid #000;
    padding: 8px;
  }

  figure[class="table"] table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #000;
    color: #000;
  }

  .question-name,
  .ielts-writing-head {
    span,
    p {
      color: #000 !important;
    }
  }
  .list-answer {
    color: #000 !important;
  }
}
