
@media (min-resolution: 120dpi) {
    #welldone{
        // height: 100vh;
        background:url("../../../public/images/mb/welcomemb.png");
        background-size: cover;
        background-attachment: scroll;
        .wd-content{
            display: flex;
            // justify-content: center;
            // align-items: center;
            flex-direction: column;
            color: #000;
            padding: 20px 20px 0 20px;
            img{
                width: 233px;
                height: 145px;
                margin: auto;
            }
            h2{
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                text-align: center;
            }
            p{
                font-size: 14px;
                line-height: 18px;
                text-align: left;
                font-weight: 400;
            }
            .input-group{
                background: #fff;
                border-radius: 5px;
                margin: 3px 0;
                input{
                    padding: 10px;
                }::placeholder{
                    color: #9F9F9F;                   
                }
            }
        }
        .wd-footer{
            display: flex;
            padding: 0 20px 20px 20px;
            .input-group{
                flex: 1;
                display: flex;
                justify-content: center;
                button{
                    width: 90%;
                    border-radius: 20px;
                    border: 1px solid #3060CF;
                    color: #3060CF;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                    text-align: center;
                    padding: 10px 12px;
                }   
                .btn-register{
                    background-color: #3060CF;
                    color: #fff;
                }
            }
        }
    }
}
