.relative {
  position: relative;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded-\[24px\] {
  border-radius: 24px;
}

.rounded-\[15px\] {
  border-radius: 15px;
}

.pb-\[5px\] {
  padding-bottom: 5px;
}

.w-\[300px\] {
  width: 300px;
}

.bg-\[\#080E28\] {
  background-color: #080e28;
}

.absolute {
  position: absolute;
}

.z-2 {
  z-index: 2;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.top-0 {
  top: 0;
}

.bottom-2 {
  bottom: 0.5rem;
}

.text-white {
  color: white;
}

.bg-\[rgb\(26\,32\,52\)\] {
  background-color: rgb(26, 32, 52);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.p-\[25px\_30px\] {
  padding: 25px 30px;
}

.space-y-3 > * + * {
  margin-top: 0.75rem;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.bg-\[\#2BAED1\] {
  background-color: #2baed1;
}

.size-\[66px\] {
  width: 66px;
  height: 66px;
}

.shrink-0 {
  flex-shrink: 0;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[rgb\(0\,240\,255\)\] {
  color: rgb(0, 240, 255);
}
.text-2BAED1 {
  color: #2baed1;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.mb-0 {
  margin-bottom: 0;
}

.grow {
  flex-grow: 1;
}

.mt-0 {
  margin-top: 0;
}

.italic {
  font-style: italic;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.font-normal {
  font-weight: 400;
}

.h-\[366px\] {
  height: 366px;
}

.w-full {
  width: 100%;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#0075cd\] {
  --tw-gradient-from: #0075cd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 117, 205, 0));
}

.to-white {
  --tw-gradient-to: white;
}

.cursor-pointer {
  cursor: pointer;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.hover\:scale-105:hover {
  transform: scale(1.05);
}

.hover\:shadow-md:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.hover\:shadow-\[rgb\(0\,240\,255\)\]:hover {
  box-shadow: 0 0 10px rgb(0, 240, 255);
}
