.survey{
    background: url("../../../public/images/survey-bg.png");
    background-position: bottom;
    background-size: cover;
    height: 100vh;
    overflow-y: scroll;
    .container-survey{
        height: calc(100% - 80px);
        .box-survey-info{
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid #000000;
            border-radius: 17px;
            padding: 20px 27px 50px 27px;
            .form-group{
                // margin: 15px 0;
                input, select{
                    min-height: 50px;
                    
                }
                input::placeholder,textarea::placeholder{
                    color: #9F9F9F;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .btn-auth{
                width: 228px;
                height: 64px;
                color: #FFFFFF;
                border: 1.5px solid #FFFFFF;
                border-radius: 15px;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                margin-top: 30px;
            }
            .btn-auth:hover{
                background-color: #00F0FF;
                color: #000000;
                
            }
        }
    }
}